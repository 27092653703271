@import "css/reset.css";
@import "css/standart.css";
@import "css/fthsc.css";
@import "css/responsive.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/*@import  "~primeicons/primeicons.css";
@import  "~primeng/resources/themes/saga-blue/theme.css";
@import  "~primeng/resources/primeng.min.css";
*/

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.p-dropdown-trigger-icon {display: none!important;}
.p-dropdown-clear-icon {display: none!important;}
.p-dropdown {width: 160px!important;}