@media screen and (min-width:180px) and (max-width:540px){
* {font-family: 'Kanit', sans-serif!important;}
.p-carousel-next, .p-carousel-prev{display: none!important;}
.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {display: none!important;}
.mobile-top {display: block;}
.mobile-menu {display: block;}
.navbar {display: block; background-color: unset!important;}

.duyurularPopup{width:320px; background: #fff; position: fixed; right:30px; bottom:30px; z-index: 11; -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); -moz-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); font-weight: 500;}
.duyurularPopup_govde:before{width:calc(100% - 10px); height:calc(100% - 10px); left:5px; top:5px; position: absolute; content:""; border:1px solid #dfdfdf; box-sizing: border-box; z-index: -1;}
.duyurularPopup h3{font-size:24px; font-weight: 600; margin-bottom: 15px; color: #364279;}
.duyurularPopup time{color:#757f94; display:block; margin-bottom: 5px; font-size:13px;}
.duyurularPopup a{color: #245092; display:block; font-size:16px;}
.duyurularPopup li{margin-bottom: 30px;}
.duyurularPopup li:last-child{margin-bottom: 0;}
.duyurularPopup a:Hover{color:#0099de;}
#duyuru-kontrol{position: absolute; width:40px; height:40px; right:0; top:-20px; border-radius: 3px;}
#duyuru-kontrol img{float:left; width:20px; margin-top: 10px; margin-left: 10px;}
#duyuru-kontrol .gizle{background: #1da800; width:40px; height:40px; border-radius: 3px; cursor:pointer;}
#duyuru-kontrol .goster{background:#a61717; display:none; width:40px; height:40px; border-radius: 3px; cursor:pointer;}
#duyuru-kontrol .gizle:Hover{background: #a61717;}
.duyurularPopup_govde{width:100%; padding:30px;}

header {width: 100%; height: 150px; position: absolute; left: 0; top: 0; z-index: 500!important; background-color: rgba(255, 255, 255, 0.8);}
.header-top {width: 100%; height: 30px; border-bottom: 1px solid #000000;}
.header-top > .inside {width: 100%; height: 30px; line-height: 30px; margin: auto;}
.header-top > .inside > .top-left {float: left;}
.header-top > .inside > .top-left > .top-dealer {width: auto; float: left; font-size: 12px;}
.header-top > .inside > .top-left > .top-dealer a {display: block; width: 100%; height: 100%; padding: 0 20px; background: #edbf9f; color: #000000; transition: all ease .5s; cursor: pointer;}
.header-top > .inside > .top-left > .top-dealer a:hover {background: #ffffff; transition: all ease .5s;}
.header-top > .inside > .top-left > .top-dealer a img {height: 14px; margin-right: 10px; vertical-align: sub;}
.header-top > .inside > .top-right {float: right;}
.header-top > .inside > .top-right > .social {width: auto; float: left; padding-left: 20px; font-size: 13px;}
.header-top > .inside > .top-right > .social > a {color: #000000; transition: all ease .5s;}
.header-top > .inside > .top-right > .social > a i {margin: 0 5px;}
.header-top > .inside > .top-right > .social > a:hover {color: #ffffff; transition: all ease .5s;}

.header-bottom {width: 100%; height: 120px; margin: auto;}
.header-bottom figure {width: 130px; height: 120px; float: none; margin: auto; line-height: 100px; position: relative;}
.header-bottom figure a {display: block; width: 130px; height: 120px; position: absolute;}
.header-bottom figure a img {width: 130px; height: 63px; margin-top: 30px;}
.header-bottom nav {width: auto; height: 120px; float: right; display: none;}
.header-bottom nav > ul {height: 120px; width: auto; display: flex; align-items: center;}
.header-bottom nav > ul > li {float: left; text-align: center; position: relative; z-index: 10;}
.header-bottom nav > ul > li > a {font-family: 'Kanit', sans-serif; letter-spacing: 2px; display: block; font-size: 16px; padding: 0 20px; color: #ffffff; line-height: 120px; transition: all ease .5s; font-weight: 300;}
.header-bottom nav > ul > li:hover a {color: #edbf9f; transition: all ease .5s;}
.header-bottom nav > ul > li:last-child > a {margin-right: 0;}
.header-bottom nav > ul > li > ul {position: absolute; top: 80px; min-width: 250px; opacity: 0; visibility: hidden; -webkit-transition: opacity 600ms, visibility 600ms; transition: opacity 600ms, visibility 600ms;}
.header-bottom nav > ul > li:hover > ul {visibility: visible; opacity: 1; position: absolute; top: 80px; z-index: 10; font-family: 'Kanit', sans-serif; height: auto; min-width: 250px;}
.header-bottom nav > ul > li > ul > li {float: left; text-align: left; border-bottom: 1px solid #ececec; width: 100%; height: 45px; background: #ffffff;}
.header-bottom nav > ul > li > ul > li:hover a {color: #edbf9f!important;}
.header-bottom nav > ul > li > ul > li > a {display: block; font-size: 14px; padding: 0 20px; color: #23222d!important; transition: all ease .5s; line-height: 45px; font-weight: 300; letter-spacing: 2px;}

.dealer-login {width: auto; height: auto; position: relative; background: #ffffff;}
.dealer-login > button {position: absolute; z-index: 999;}
.dealer-login > .inside {width: 700px; height: auto; margin: auto; padding: 30px 30px 0 30px; position: relative; background: #ffffff;}
.dealer-login > .inside > .dealer-head {width: 100%; height: 102px;}
.dealer-login > .inside > .dealer-head > figure {width: 180px; height: 102px; margin:auto;}
.dealer-login > .inside > .dealer-head > h1 {width: auto; height: auto; text-align: center; font-size: 32px; font-weight: 700; line-height: 45px;}
.dealer-login > .inside > .modal-body > form > ul {float: none; clear: both; width: 310px; margin: 50px auto; height: auto; display: table;}
.dealer-login > .inside > .modal-body > form > ul > li {float: left; height: 40px; margin-bottom: 20px;}
.dealer-login > .inside > .modal-body > form > ul > li > img {height: 40px;}
.dealer-login > .inside > .modal-body > form > ul > li:nth-child(1n) {width: 60px;}
.dealer-login > .inside > .modal-body > form > ul > li:nth-child(2n) {width: 250px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="text"] {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="password"] {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="text"]:focus {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="password"]:focus {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > input[type="submit"] {float: left; cursor: pointer; width: 180px; height: 50px; border-radius: 10px; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px; margin: auto; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > form > input[type="submit"]:hover {background-color: #cfcfcf; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > .dealer-links {width: fit-content; height: auto; float: right;}
.dealer-login > .inside > .modal-body > .dealer-links > a {color: #5574e4; display: block; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > .dealer-links > a:hover {color: #cfcfcf; transition: all ease .5s;}

ngb-carousel {max-width: 700px; margin: 50px auto;}
ngb-carousel img {width: 100%; outline: none;}
ngb-carousel {width: inherit; height: inherit;}
.carousel-inner {overflow: visible;}
.carousel-item {display: flex !important; opacity: 0; visibility: hidden; transition: opacity 1.2s ease-in-out, visibility 1.2s; z-index: -1;}
.carousel-item.active{opacity: 1; visibility: visible; z-index: 10;}
.carousel-control-prev {z-index: 20;}
.carousel-control-next {z-index: 20;}
.carousel-indicators{z-index: 20;}

.mobilSl {display: block;}
.desktopSl {display: none;}

#slider {position: relative; width: 100%; height: 100vh!important; z-index: 0;/* background-image: url(../images/slide.jpg); background-repeat: no-repeat; background-position: center; background-size: cover;*/}
#slider .swiper-slide {margin-right: 0!important; position: relative; z-index: 0;}
.swiper-slide a {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
.slider-text {width: 80%; height: auto; position: absolute; z-index: 100; left: 0; right: 0; bottom: 5vh;}
.slider-text h1 {font-family: 'Kanit', sans-serif; text-shadow: 3px 3px #000; font-size:  36px!important; font-weight: 800; color: #edbf9f;}
.slider-text h2 {font-family: 'Kanit', sans-serif; text-shadow: 3px 3px #000; font-size: 36px!important; font-weight: 800; color: #ffffff;}
.slider-text > a {position: relative; margin: auto;  display: block; width: 270px; height: initial; padding: 15px 50px; border: 1px solid #ffffff; color: #ffffff; font-size: 16px; font-weight: 300; font-style: italic; text-align: center; transition: all ease .5s;}
.slider-text > a > span {transition: all ease .5s; color: #ffffff;}
.slider-text > a:hover > span {transition: all ease .5s; color: #000000;}
.slider-text > a:hover {background: #ffffff; color: #f15d24; transition: all ease .5s;}
.slider-text2 > a {position: relative; margin: 30px auto;  display: block; width: 270px; height: initial; padding: 15px 50px; border: 1px solid #ffffff; color: #ffffff; font-size: 16px; font-weight: 300; font-style: italic; text-align: center; transition: all ease .5s;}
.slider-text2 > a > span {transition: all ease .5s; color: #ffffff;}
.slider-text2 > a:hover > span {transition: all ease .5s; color: #000000;}
.slider-text2 > a:hover {background: #ffffff; color: #f15d24; transition: all ease .5s;}
.swiper-button-next,.swiper-button-prev{background:none!important;}
.slider-text, .slider-text2 {display: inline-block; color:#fff; margin: auto; padding: 30px;}
#slider .swiper-slide{display:flex; align-items: center;}

#our-products {display: block; width: 100%; height: auto;}
#our-products > .inside {width: 100%; height: auto; margin: auto; font-family: 'Kanit', sans-serif;}
#product-slider {overflow:hidden; width:100%; position:relative; height: 675px; margin: auto; padding-top: 50px;}
#product-slider > h1 {width: 100%; height: 80px; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; margin-bottom: 25px; padding-left: 0; background-image: url(/assets/images/modules-logo.png); background-repeat: no-repeat; background-position: bottom center; text-align: center;}
.carousel {height: 300px!important;}
.ps {box-shadow: none; margin: 0; background-color: #fbfbfb; text-align: center;  height: 290px!important; position: relative; margin-right: 0px;}
.ps h2 {font-size: 14px; font-weight: 500; color: #484848; padding: 10px;}
.ps span {font-size: 14px; font-weight: 200; color: #595959; padding: 10px;}
.product-slide {position:relative}
.product-slide img {width: 300px!important; height: auto!important;}
.swiper-button-next,.swiper-button-prev {background:none!important;}
.product-slider-text {padding:10px; display: inline-block; color:#fff; margin: auto; margin-top: 20px;}
#slider .product-slide {display:flex; align-items: center;}

.opd {display: none;}
.opm {display: block;}
.opt {display: none;}
.opt2 {display: none;}
.urunler-item {margin: 10px!important; padding: 10px!important;}

#production {width: 100%; height: 300px; position: relative; overflow: hidden; display: table;}
#production > .inside {width: 100%; height: auto; position: relative; z-index: 999; margin: 50px auto; display: table;}
#production > .inside > h1 {text-align: right; width: 100%; height: 80px; text-align: center; font-size: 20px; color: #ffffff; font-weight: 700; letter-spacing: 2px; padding-right: 0px; background-image: url(/assets/images/modules-logo-white.png); background-repeat: no-repeat; background-position: bottom center;}
#production > .inside > ul {clear: both; width: 100%; height: auto; margin-top: initial; margin: 50px auto 0 auto;}
#production > .inside > ul > li {position: relative; width: 100%; height: 400px; margin: 0; padding: 20px; float: none; text-align: center;}
#production > .inside > ul > li > h2 {position: relative; display: block; width: 100%; text-align: center; top: 0; font-size: 18px; color: #ffffff; font-weight: 500;}
#production > .inside > ul > li:hover > h2 {top: 0px;}
#production > .inside > ul > li > span {position: relative; display: block; top: 0px; text-align: center; font-size: 12px; color: #ffffff; font-weight: 300;}
#production > .inside > ul > li:hover > span {top: 0px;}
#production > .inside > ul > li > img {opacity: 1; border-radius: 10px;}
#production > .inside > ul > li:hover > img {opacity: 1;}
.video-cover {position: absolute; z-index: 1; left: 0; top: 0; width: 100%; height: 100%; background-color: #8f0006; opacity: 0.7;}
video {top: 0; right: -75px; min-width: 100%; min-height: 100%; width: auto; height: 100%; z-index: 0; position: absolute;}

#main-contact {width: 100%; height: 500px; position: relative;}
#main-contact > .inside {width: 100%; margin: 50px auto;}
#main-contact > #map {width: 100%; height: 400px;}
#main-contact > .inside > h1 {width: 100%; height: 80px; text-align: center; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; padding-left: 0; background-image: url(/assets/images/modules-logo.png); background-repeat: no-repeat; background-position: bottom center;}
#main-contact > .inside > span {display: none;}
#main-contact > iframe {height: 370px!important;}

#main-news {width: 100%; height: auto; position: relative; background: #ececec;}
#main-news > .inside {width: 100%; height: auto; position: relative; margin: auto; padding-top: 50px; text-align: center;}
#main-news > .inside > h1 {text-align: center; width: 100%; height: 80px; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; margin: auto; background: url(/assets/images/modules-logo.png) no-repeat bottom center;}
#main-news > .inside > ul {width: 100%; height: auto; display: table; margin-top: 50px;}
#main-news > .inside > ul > li {width: auto; height: auto; display: table; position: relative; padding: 0 20px 30px 20px;}
#main-news > .inside > ul > li > a {width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 500;}
#main-news > .inside > ul > li > figure > img {width: 100%; height: 100%; border-radius: 10px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > figure > img {opacity: .7; transition: all ease .5s;}
#main-news > .inside > ul > li > div > h2 {font-family: 'Kanit', sans-serif; font-size: 18px; font-weight: 500; color: #000000; margin-top: 20px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > div > h2 {color: #a1a1a1; transition: all ease .5s;}
#main-news > .inside > ul > li > div > p {font-family: 'Kanit', sans-serif; font-size: 12px; font-weight: 200; color: #000000; margin-top: 20px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > div > p {color: #a1a1a1; transition: all ease .5s;}

footer {width: 100%; height: 150px; background: #2f2f2f;}
footer > .inside {width: 100%; height: 150px; margin: auto;}

footer>.whatsapp {position: fixed; bottom: 50px; left: 50px; width: 75px; height: 75px; z-index: 999;}
footer>.whatsapp>button {background: none;}
footer>.whatsapp>button>img {width: 50px; height: inherit;}
footer>.whatsapp>.whatsapp-inside {position: absolute; width: 370px; padding: 15px 25px; bottom: 100px; border-radius: 20px; border: 1px solid #e9e9e9; background-color: #ffffff;}
footer>.whatsapp>.whatsapp-inside>a {display: block; color: #000000; font-size: 16px; font-weight: 300; line-height: 40px; height: 40px;}
footer>.whatsapp>.whatsapp-inside>a>img {width: 25px; height: 25px; vertical-align: text-bottom; margin-right: 10px;}
footer>.whatsapp>.whatsapp-inside>a:hover {color: #005312;}




footer > .inside > ul {display: block;}
footer > .inside > ul > li {flex: 1; line-height: 25px; text-align: center;}
footer > .inside > ul > li > a {display: block; font-size: 14px; color: #ffffff; font-weight: 200; transition: all ease .5s;}
footer > .inside > ul > li > a:hover {color: #edbf9f; transition: all ease .5s;}
footer > .inside > ul > li > span {font-size: 14px; color: #ffffff; font-weight: 200; margin: 0 7px;}
footer > .inside > ul > li > span > a > img {max-height: 20px!important; vertical-align: sub!important;}
footer > .inside > ul > li:first-child {text-align: center; margin-bottom: 20px;}
footer > .inside > ul > li:last-child {text-align: center;}

main {background: #ffffff;}

.breadcrumb {width: 100%; height: auto; position: absolute; left: 0; right: 0; bottom: 0; margin: auto; background: none;}
#breadcrumb {width: auto; display: none; align-items: center; position: absolute; bottom: 20px; justify-content: left;  font-family: 'Kanit', sans-serif; font-weight: 300; font-size: 16px;}
#breadcrumb > span {font-family: 'Kanit', sans-serif; width: 94%; height: 1px; background: #e5e5e5; margin: auto; display: block;}
#breadcrumb li a {font-family: 'Kanit', sans-serif; color: #ffffff; width: 100%; height: 100%; display: block;}
#breadcrumb li a span {font-family: 'Kanit', sans-serif; color: #ffffff;}
#breadcrumb li:last-child a {font-family: 'Kanit', sans-serif; color: #ffffff; width: 100%; height: 100%; display: block;}
#breadcrumb li:last-child span {font-family: 'Kanit', sans-serif; color: #ffffff;}
#breadcrumb li {font-family: 'Kanit', sans-serif; position: relative; float: left; display: flex; color: #ffffff; align-items: inherit;}
#breadcrumb li:before {content: ">"; padding: 0 7px; color: #ffffff;}
#breadcrumb li:first-child:before {content: ""; padding: 0;}
#breadcrumb li:last-child:before {color: #ffffff; padding-top: 3px;}
#breadcrumb li mark {background: none; color: #ffffff;}

#article-text {width: 100%; height: auto; min-height: 350px; margin: auto; padding: 50px 15px 30px 15px; display: block; overflow-x: scroll;}
#article-text figure {width: 50%!important; height: inherit; float: left; margin-right: 50px;}
#article-text figure img {border-radius: 10px;}
#article-text h1 {width: auto; font-size: 40px; color: #4c4c4c; font-weight: 700; background: url(/images/modules-logo) no-repeat left center; padding-left: 50px; float: left;}
#article-text h2 {width: auto; font-size: 24px; color: #4c4c4c; font-weight: 400;}
#article-text h3 {font-family: 'Kanit', sans-serif; display: inline-block; letter-spacing: 1px; font-size: 22px; line-height: 32px; color: #4c4c4c; font-weight: 500;}
#article-text p {font-family: 'Kanit', sans-serif; font-size: 16px; letter-spacing: 1px; line-height: 30px; color: #4c4c4c; font-weight: 300; margin: 20px 0;}
#article-text p a {color: #006297; font-weight: 500; transition: all ease .5s; text-decoration: none;}
#article-text p a:hover {color: #000000; transition: all ease .5s;}

#content {width: 100%; height:auto; position: relative;}
#content2 {width: 100%; height:auto; position: relative; background-color: #dfdfdf;}
#content figure {width: 100%!important; height: 300px; background-repeat: no-repeat; background-position: center; background-size: cover; position: relative;}
#content figure h1 {font-family: 'Kanit', sans-serif; font-size: 30px; line-height: 50px; color: #ffffff; font-weight: 500;}
#content figure h5 {font-family: 'Kanit', sans-serif; font-size: 20px; color: #ffffff; opacity: 0.5;}
#content > article {width: 100%; height: auto; padding: 50px 0; margin: auto;}
#content > article > h2 {font-family: 'Kanit', sans-serif; font-size: 26px; margin: 15px 0 0 0;}
#content > article > h3 {font-family: 'Kanit', sans-serif; font-size: 24px; border-bottom: 1px solid #636363; width: fit-content; color: #636363; font-weight: 400; margin: 30px 0 20px 0;}
#content > article > h4 {font-family: 'Kanit', sans-serif; font-size: 16px; color: #636363; font-weight: 500; margin: 10px 0;}
#content > article > p {font-family: 'Kanit', sans-serif; font-size: 14px; color: #666666; font-weight: 300; line-height: 24px; margin-top: 15px;}

.kvkk-liste {
    padding: 20px 0 30px 0;
    list-style-type: disc;
}

.kvkk-liste li {
    margin: 5px 0 5px 20px;
    font-weight: 300;
}

.kvkk-alfanumerik {
    padding: 20px 0 30px 0;
    list-style-type: lower-latin;
}

.kvkk-alfanumerik li {
    margin: 5px 0 5px 20px;
    font-weight: 300;
}

.kvkk-alfanumerik li::marker {
    font-weight: 700;
}

.kvkk-iletisim {
    width: 100%;
    display: block;
}

.kvkk-iletisim li {
    float: left;
}

.kvkk-iletisim li:nth-child(1n) {
    width: 30%;
}

.kvkk-iletisim li:nth-child(2n) {
    width: 70%;
}

.makine-parkuru {width: 100%; height: 100%;}
.makine-parkuru tr {border: 1px solid #000000;}
.makine-parkuru tr td {white-space: nowrap; padding: 15px 7px;}
.makine-parkuru tr td {vertical-align: middle; border-left: 1px solid #000000;}

.organization-schema {width: 100%; height: 100%;}
.organization-schema tr {border: 1px solid #000000;}
.organization-schema tr th {white-space: nowrap; padding: 15px 7px; font-weight: 700; font-size: 22px; border-left: 1px solid #000000;}
.organization-schema tr td {white-space: nowrap; padding: 15px 7px; vertical-align: middle; border-left: 1px solid #000000;}
.organization-schema tr td a {color: #9b0000; transition: all ease .5s;}
.organization-schema tr td a:hover {color: #000000; transition: all ease .5s;}

.products > .prods {width: 100%; height: auto; max-height: auto; margin-bottom: 50px; display: table;}
.products > .prods > figure {width: 100%!important; max-width: 400px!important; height: 285px!important; float: none; background-size: cover!important; background-repeat: no-repeat; background-position: center; /*background-attachment: fixed;*/ margin: 0!important; padding: 0;}
.products > .prods > content {width: 100%; margin-left: 0; padding-top: 30px; display: table; float: none;}
.products > .prods > content > div {width: 100%; display: block; margin: 30px 0;}
.products > .prods > content > div > button:first-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #758a71; border: 1px solid #ffffff; transition: all ease .5s;}
.products > .prods > content > div > button:first-child:hover {background-color: #ffffff; color: #758a71; border: 1px solid #758a71; transition: all ease .5s;}
.products > .prods > content > div > button:last-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #1da800; border: 1px solid #ffffff; transition: all ease .5s;}
.products > .prods > content > div > button:last-child:hover {background-color: #ffffff; color: #1da800; border: 1px solid #1da800; transition: all ease .5s;}
.products > .prods > content h2, .products > .prods > content h3 {width: 100%; display: block;}
.prods > a {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}

.services > .srv {width: 100%; height: auto; max-height: auto; margin-bottom: 50px; display: table;}
.services > .srv > figure {width: 100%!important; max-width: 400px!important; height: 285px!important; float: none; background-size: cover!important; background-repeat: no-repeat; background-position: center; /*background-attachment: fixed;*/ margin: 0!important; padding: 0;}
.services > .srv > content {width: 100%; margin-left: 0; padding-top: 30px; display: table; float: none;}
.services > .srv > content > div {width: 100%; display: block; margin: 30px 0;}
.services > .srv > content > div > button:first-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #758a71; border: 1px solid #ffffff; transition: all ease .5s;}
.services > .srv > content > div > button:first-child:hover {background-color: #ffffff; color: #758a71; border: 1px solid #758a71; transition: all ease .5s;}
.services > .srv > content > div > button:last-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #1da800; border: 1px solid #ffffff; transition: all ease .5s;}
.services > .srv > content > div > button:last-child:hover {background-color: #ffffff; color: #1da800; border: 1px solid #1da800; transition: all ease .5s;}
.services > .srv > content h2, .products > .prods > content h3 {width: 100%; display: block;}

.header-bg {background: #333333; width: 100%; height: 150px;}
.dealer-area {width: 100%; height: auto; margin: 50px auto;}
.dealer-area > h1 {width: auto; font-size: 40px; color: #4c4c4c; font-weight: 700; padding-left: 50px;}
.dealer-area > ul {width: 100%; height: auto; display: table; margin: 50px auto;}
.dealer-area > ul > li {width: 350px; height: 200px; float: left; margin-right: 50px; margin-bottom: 50px; transition: all ease .5s;}
.dealer-area > ul > li:hover {transition: all ease .5s; cursor: pointer;}
.dealer-area > ul > li:nth-child(3n) {margin-right: 0px;}
.dealer-area > ul > li:nth-child(-n+3) {background: #c67d7f;}
.dealer-area > ul > li:nth-child(-n+3):hover {background: #ffa3a6;}
.dealer-area > ul > li:nth-child(n+4) {background: #7d90c6;}
.dealer-area > ul > li:nth-child(n+4):hover {background: #94adf5;}
.dealer-area > ul > li:nth-child(n+7) {background: #1ca800;}
.dealer-area > ul > li:nth-child(n+7):hover {background: #28dd04;}
.dealer-area > ul > li:nth-child(9) {background: #1ca800;}
.dealer-area > ul > li:nth-child(9):hover {background: #28dd04;}
.dealer-area > ul > li > figure {width: 80px; height: 80px; margin: auto; margin-top: 30px;}
.dealer-area > ul > li > figure > img {width: 100%; height: 100%;}
.dealer-area > ul > li > h2 {text-align: center; font-size: 24px; font-weight: 300; color: #ffffff; margin-top: 20px;}

.contact {width: 100%; height: auto; margin: 50px auto;}
.contact > ul {width: 100%; height: auto; display: table;}
.contact > ul > li {float: left; width: 100%; padding: 20px;}
.contact > ul > li i {color: #c06c31; margin-right: 7px; vertical-align: middle;}
.contact > ul > li > h1 {font-size: 22px; color: #2e2e2e; line-height: 36px;}
.contact > ul > li > h2 {font-size: 18px; color: #2e2e2e; line-height: 36px; margin-top: 30px;}
.contact > ul > li > address {line-height: 36px;}
.contact > ul > li > address > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > address > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > .mail {line-height: 36px;}
.contact > ul > li > .mail > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > .mail > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > .phone {line-height: 36px;}
.contact > ul > li > .phone > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > .phone > a:hover {color: #cfcfcf; transition: all ease .5s;}

.contact > ul > li > form > div > span {display: block; font-size: 14px; color: #2e2e2e; margin: 10px 0;}
.contact > ul > li > form > div > input {width: 100%; height: 40px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e; background: #ffffff;}
.contact > ul > li > form > div > button {width: 150px; height: 40px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e; background: #ffffff; margin-top: 30px; transition: all ease .5s; cursor: pointer;}
.contact > ul > li > form > div > button:hover {background: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > form > div > textarea {width: 100%; height: 100px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e;}
}

@media screen and (min-width:541px) and (max-width:1122px){
    
* {font-family: 'Kanit', sans-serif!important;}
.p-carousel-next, .p-carousel-prev{display: none!important;}

.duyurularPopup{width:320px; background: #fff; position: fixed; right:30px; bottom:30px; z-index: 11; -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); -moz-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); font-weight: 500;}
.duyurularPopup_govde:before{width:calc(100% - 10px); height:calc(100% - 10px); left:5px; top:5px; position: absolute; content:""; border:1px solid #dfdfdf; box-sizing: border-box; z-index: -1;}
.duyurularPopup h3{font-size:24px; font-weight: 600; margin-bottom: 15px; color: #364279;}
.duyurularPopup time{color:#757f94; display:block; margin-bottom: 5px; font-size:13px;}
.duyurularPopup a{color: #245092; display:block; font-size:16px;}
.duyurularPopup li{margin-bottom: 30px;}
.duyurularPopup li:last-child{margin-bottom: 0;}
.duyurularPopup a:Hover{color:#0099de;}
#duyuru-kontrol{position: absolute; width:40px; height:40px; right:0; top:-20px; border-radius: 3px;}
#duyuru-kontrol img{float:left; width:20px; margin-top: 10px; margin-left: 10px;}
#duyuru-kontrol .gizle{background: #1da800; width:40px; height:40px; border-radius: 3px; cursor:pointer;}
#duyuru-kontrol .goster{background:#a61717; display:none; width:40px; height:40px; border-radius: 3px; cursor:pointer;}
#duyuru-kontrol .gizle:Hover{background: #a61717;}
.duyurularPopup_govde{width:100%; padding:30px;}

header {width: 100%; height: 150px; position: absolute; left: 0; top: 0; z-index: 500!important; background-color: rgba(255, 255, 255, 0.8);}
.header-top {width: 100%; height: 30px; border-bottom: 1px solid #000000;}
.header-top > .inside {width: 100%; height: 30px; line-height: 30px; margin: auto;}
.header-top > .inside > .top-left {float: left;}
.header-top > .inside > .top-left > .top-dealer {width: auto; float: left; font-size: 12px;}
.header-top > .inside > .top-left > .top-dealer a {display: block; width: 100%; height: 100%; padding: 0 20px; background: #edbf9f; color: #000000; transition: all ease .5s; cursor: pointer;}
.header-top > .inside > .top-left > .top-dealer a:hover {background: #ffffff; transition: all ease .5s;}
.header-top > .inside > .top-left > .top-dealer a img {height: 14px; margin-right: 10px; vertical-align: sub;}
.header-top > .inside > .top-right {float: right;}
.header-top > .inside > .top-right > .social {width: auto; float: left; padding-left: 20px; font-size: 13px;}
.header-top > .inside > .top-right > .social > a {color: #000000; transition: all ease .5s;}
.header-top > .inside > .top-right > .social > a i {margin: 0 5px;}
.header-top > .inside > .top-right > .social > a:hover {color: #ffffff; transition: all ease .5s;}

.header-bottom {width: 100%; height: 120px; margin: auto;}
.header-bottom figure {width: 130px; height: 120px; float: none; margin: auto; line-height: 100px; position: relative;}
.header-bottom figure a {display: block; width: 130px; height: 120px; position: absolute;}
.header-bottom figure a img {width: 130px; height: 63px; margin-top: 30px;}
.header-bottom nav {width: auto; height: 120px; float: right; display: none;}
.header-bottom nav > ul {height: 120px; width: auto; display: flex; align-items: center;}
.header-bottom nav > ul > li {float: left; text-align: center; position: relative; z-index: 10;}
.header-bottom nav > ul > li > a {font-family: 'Kanit', sans-serif; letter-spacing: 2px; display: block; font-size: 16px; padding: 0 20px; color: #ffffff; line-height: 120px; transition: all ease .5s; font-weight: 300;}
.header-bottom nav > ul > li:hover a {color: #edbf9f; transition: all ease .5s;}
.header-bottom nav > ul > li:last-child > a {margin-right: 0;}
.header-bottom nav > ul > li > ul {position: absolute; top: 80px; min-width: 250px; opacity: 0; visibility: hidden; -webkit-transition: opacity 600ms, visibility 600ms; transition: opacity 600ms, visibility 600ms;}
.header-bottom nav > ul > li:hover > ul {visibility: visible; opacity: 1; position: absolute; top: 80px; z-index: 10; font-family: 'Kanit', sans-serif; height: auto; min-width: 250px;}
.header-bottom nav > ul > li > ul > li {float: left; text-align: left; border-bottom: 1px solid #ececec; width: 100%; height: 45px; background: #ffffff;}
.header-bottom nav > ul > li > ul > li:hover a {color: #edbf9f!important;}
.header-bottom nav > ul > li > ul > li > a {display: block; font-size: 14px; padding: 0 20px; color: #23222d!important; transition: all ease .5s; line-height: 45px; font-weight: 300; letter-spacing: 2px;}

.dealer-login {width: auto; height: auto; position: relative; background: #ffffff;}
.dealer-login > button {position: absolute; z-index: 999;}
.dealer-login > .inside {width: 700px; height: auto; margin: auto; padding: 30px 30px 0 30px; position: relative; background: #ffffff;}
.dealer-login > .inside > .dealer-head {width: 100%; height: 102px;}
.dealer-login > .inside > .dealer-head > figure {width: 180px; height: 102px; margin:auto;}
.dealer-login > .inside > .dealer-head > h1 {width: auto; height: auto; text-align: center; font-size: 32px; font-weight: 700; line-height: 45px;}
.dealer-login > .inside > .modal-body > form > ul {float: none; clear: both; width: 310px; margin: 50px auto; height: auto; display: table;}
.dealer-login > .inside > .modal-body > form > ul > li {float: left; height: 40px; margin-bottom: 20px;}
.dealer-login > .inside > .modal-body > form > ul > li > img {height: 40px;}
.dealer-login > .inside > .modal-body > form > ul > li:nth-child(1n) {width: 60px;}
.dealer-login > .inside > .modal-body > form > ul > li:nth-child(2n) {width: 250px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="text"] {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="password"] {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="text"]:focus {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="password"]:focus {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > input[type="submit"] {float: left; cursor: pointer; width: 180px; height: 50px; border-radius: 10px; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px; margin: auto; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > form > input[type="submit"]:hover {background-color: #cfcfcf; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > .dealer-links {width: fit-content; height: auto; float: right;}
.dealer-login > .inside > .modal-body > .dealer-links > a {color: #5574e4; display: block; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > .dealer-links > a:hover {color: #cfcfcf; transition: all ease .5s;}

ngb-carousel {max-width: 700px; margin: 50px auto;}
ngb-carousel img {width: 100%; outline: none;}
ngb-carousel {width: inherit; height: inherit;}
.carousel-inner {overflow: visible;}
.carousel-item {display: flex !important; opacity: 0; visibility: hidden; transition: opacity 1.2s ease-in-out, visibility 1.2s; z-index: -1;}
.carousel-item.active{opacity: 1; visibility: visible; z-index: 10;}
.carousel-control-prev {z-index: 20;}
.carousel-control-next {z-index: 20;}
.carousel-indicators{z-index: 20;}

.mobilSl {display: block;}
.desktopSl {display: none;}
#slider {position: relative; width: 100%; height: 100vh!important; z-index: 0;/* background-image: url(../images/slide.jpg); background-repeat: no-repeat; background-position: center; background-size: cover;*/}
#slider .swiper-slide {margin-right: 0!important; position: relative; z-index: 0;}
.swiper-slide a {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
.slider-text {width: 80%; height: auto; position: absolute; z-index: 100; left: 0; right: 0; bottom: 5vh;}
.slider-text h1 {font-family: 'Kanit', sans-serif; text-shadow: 3px 3px #000; font-size: 46px!important; font-weight: 800; color: #edbf9f; margin-left: 50px!important;}
.slider-text h2 {font-family: 'Kanit', sans-serif; text-shadow: 3px 3px #000; font-size: 46px!important; font-weight: 800; color: #ffffff; margin-left: 100px!important;}
.slider-text > a {position: relative; margin: auto;  display: block; width: 270px; height: initial; padding: 15px 50px; border: 1px solid #ffffff; color: #ffffff; font-size: 26px; font-weight: 300; font-style: italic; text-align: center; transition: all ease .5s;}
.slider-text > a > span {transition: all ease .5s; color: #ffffff;}
.slider-text > a:hover > span {transition: all ease .5s; color: #000000;}
.slider-text > a:hover {background: #ffffff; color: #f15d24; transition: all ease .5s;}
.slider-text2 > a {position: relative; margin: 30px auto;  display: block; width: 270px; height: initial; padding: 15px 50px; border: 1px solid #ffffff; color: #ffffff; font-size: 16px; font-weight: 300; font-style: italic; text-align: center; transition: all ease .5s;}
.slider-text2 > a > span {transition: all ease .5s; color: #ffffff;}
.slider-text2 > a:hover > span {transition: all ease .5s; color: #000000;}
.slider-text2 > a:hover {background: #ffffff; color: #f15d24; transition: all ease .5s;}
.swiper-button-next,.swiper-button-prev{background:none!important;}

.slider-text, .slider-text2 {display: inline-block; color:#fff; margin: auto; padding: 30px;}
#slider .swiper-slide{display:flex; align-items: center;}
#slider > .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {display: none!important;}
.opd {display: block;}
.opm{display: none;}
.opt {display: none;}
.opt2 {display: none;}
.p-link {width: 15px; height: 15px; background-color: #757f94; margin: 0 7px; border-radius: 7px;}
#our-products > .inside {width: 100%; height: auto; margin: auto; font-family: 'Kanit', sans-serif;}
#product-slider {overflow:hidden; width:100%; position:relative; height: 600px; margin: auto; padding-top: 50px;}
#product-slider > h1 {width: 100%; height: 80px; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; margin-bottom: 25px; padding-left: 0; background-image: url(/assets/images/modules-logo.png); background-repeat: no-repeat; background-position: bottom center; text-align: center;}
.carousel {height: 300px!important;}
.ps {box-shadow: none; margin: 0; background-color: #fbfbfb; text-align: center; width: 385px!important; height: 290px!important; position: relative; margin-right: 0px;}
.ps h2 {font-size: 14px; font-weight: 500; color: #484848; padding: 10px;}
.ps span {font-size: 14px; font-weight: 200; color: #595959; padding: 10px;}
.product-slide {position:relative}
.product-slide img {width: 300px!important; height: auto!important;}
.swiper-button-next,.swiper-button-prev {background:none!important;}
.product-slider-text {padding:10px; display: inline-block; color:#fff; margin: auto; margin-top: 20px;}
#slider .product-slide {display:flex; align-items: center;}
.urunler-item {margin: 10px!important; padding: 10px!important;}

#production {width: 100%; height: 450px; position: relative; overflow: hidden; display: table;}
#production > .inside {width: 100%; height: auto; position: relative; z-index: 999; margin: 50px auto; display: table;}
#production > .inside > h1 {text-align: right; width: 100%; height: 80px; text-align: center; font-size: 20px; color: #ffffff; font-weight: 700; letter-spacing: 2px; padding-right: 0px; background-image: url(/assets/images/modules-logo-white.png); background-repeat: no-repeat; background-position: bottom center;}
#production > .inside > ul {clear: both; width: 100%; height: auto; margin-top: initial; margin: 50px auto 0 auto;}
#production > .inside > ul > li {position: relative; width: 100%; height: 400px; margin: 0; padding: 20px; float: none; text-align: center;}
#production > .inside > ul > li > h2 {position: relative; display: block; width: 100%; text-align: center; top: 0; font-size: 18px; color: #ffffff; font-weight: 500;}
#production > .inside > ul > li:hover > h2 {top: 0px;}
#production > .inside > ul > li > span {position: relative; display: block; top: 0px; text-align: center; font-size: 12px; color: #ffffff; font-weight: 300;}
#production > .inside > ul > li:hover > span {top: 0px;}
#production > .inside > ul > li > img {opacity: 1; border-radius: 10px;}
#production > .inside > ul > li:hover > img {opacity: 1;}
.video-cover {position: absolute; z-index: 1; left: 0; top: 0; width: 100%; height: 100%; background-color: #8f0006; opacity: 0.7;}
video {top: 0; left: 0; min-width: 100%; min-height: 100%; width: auto; height: 100%; z-index: 0; position: absolute;}

#main-contact {width: 100%; height: 500px; position: relative;}
#main-contact > .inside {width: 100%; margin: 50px auto;}
#main-contact > #map {width: 100%; height: 400px;}
#main-contact > .inside > h1 {width: 100%; height: 80px; text-align: center; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; padding-left: 0; background-image: url(/assets/images/modules-logo.png); background-repeat: no-repeat; background-position: bottom center;}
#main-contact > .inside > span {display: none;}
#main-contact > iframe {height: 370px!important;}

#main-news {width: 100%; height: auto; position: relative; background: #ececec;}
#main-news > .inside {width: 100%; height: auto; position: relative; margin: auto; padding-top: 50px; text-align: center;}
#main-news > .inside > h1 {text-align: center; width: 100%; height: 80px; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; margin: auto; background: url(/assets/images/modules-logo.png) no-repeat bottom center;}
#main-news > .inside > ul {width: 100%; height: auto; display: flex; margin-top: 50px;}
#main-news > .inside > ul > li {width: auto; height: auto; flex: 1; position: relative; padding: 0 20px 30px 20px;}
#main-news > .inside > ul > li > a {width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 500;}
#main-news > .inside > ul > li > figure > img {width: 100%; height: 100%; border-radius: 10px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > figure > img {opacity: .7; transition: all ease .5s;}

#main-news > .inside > ul > li > div > h2 {font-family: 'Kanit', sans-serif; font-size: 18px; font-weight: 500; color: #000000; margin-top: 20px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > div > h2 {color: #a1a1a1; transition: all ease .5s;}
#main-news > .inside > ul > li > div > p {font-family: 'Kanit', sans-serif; font-size: 12px; font-weight: 200; color: #000000; margin-top: 20px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > div > p {color: #a1a1a1; transition: all ease .5s;}

footer {width: 100%; height: 150px; background: #2f2f2f;}
footer > .inside {width: 100%; height: 150px; margin: auto;}
footer>.whatsapp {position: fixed; bottom: 50px; left: 50px; width: 75px; height: 75px; z-index: 999;}
footer>.whatsapp>button {background: none;}
footer>.whatsapp>button>img {width: 50px; height: inherit;}
footer>.whatsapp>.whatsapp-inside {position: absolute; width: 350px; padding: 15px 25px; bottom: 100px; border-radius: 20px; border: 1px solid #e9e9e9; background-color: #ffffff;}
footer>.whatsapp>.whatsapp-inside>a {display: block; color: #000000; font-size: 16px; font-weight: 300; line-height: 40px; height: 40px;}
footer>.whatsapp>.whatsapp-inside>a>img {width: 25px; height: 25px; vertical-align: text-bottom; margin-right: 10px;}
footer>.whatsapp>.whatsapp-inside>a:hover {color: #005312;}



footer > .inside > ul {display: block;}
footer > .inside > ul > li {flex: 1; line-height: 25px; text-align: center;}
footer > .inside > ul > li > a {display: block; font-size: 14px; color: #ffffff; font-weight: 200; transition: all ease .5s;}
footer > .inside > ul > li > a:hover {color: #edbf9f; transition: all ease .5s;}
footer > .inside > ul > li > span {font-size: 14px; color: #ffffff; font-weight: 200; margin: 0 7px;}
footer > .inside > ul > li > span > a > img {max-height: 20px!important; vertical-align: sub!important;}
footer > .inside > ul > li:first-child {text-align: center; margin-bottom: 20px;}
footer > .inside > ul > li:last-child {text-align: center;}

main {background: #ffffff;}

.breadcrumb {width: 100%; height: 110px; position: absolute; left: 0; right: 0; bottom: 0; margin: auto; background: none;}
#breadcrumb {width: 100%; display: flex; align-items: center; position: absolute; bottom: 20px; justify-content: left;  font-family: 'Kanit', sans-serif; font-weight: 300; font-size: 16px;}
#breadcrumb > span {font-family: 'Kanit', sans-serif; width: 94%; height: 1px; background: #e5e5e5; margin: auto; display: block;}
#breadcrumb li a {font-family: 'Kanit', sans-serif; color: #ffffff; width: 100%; height: 100%; display: block;}
#breadcrumb li a span {font-family: 'Kanit', sans-serif; color: #ffffff;}
#breadcrumb li:last-child a {font-family: 'Kanit', sans-serif; color: #ffffff; width: 100%; height: 100%; display: block;}
#breadcrumb li:last-child span {font-family: 'Kanit', sans-serif; color: #ffffff;}
#breadcrumb li {font-family: 'Kanit', sans-serif; position: relative; float: left; display: flex; color: #ffffff; align-items: inherit;}
#breadcrumb li:before {content: ">"; padding: 0 7px; color: #ffffff;}
#breadcrumb li:first-child:before {content: ""; padding: 0;}
#breadcrumb li:last-child:before {color: #ffffff; padding-top: 3px;}
#breadcrumb li mark {background: none; color: #ffffff;}

#article-text {width: 100%; height: auto; margin: auto; padding: 50px 20px; display: table;}
#article-text figure {width: 50%!important; height: inherit; float: left; margin-right: 50px;}
#article-text figure img {border-radius: 10px;}
#article-text h1 {width: auto; font-size: 40px; color: #4c4c4c; font-weight: 700; background: url(/images/modules-logo) no-repeat left center; padding-left: 50px; float: left;}
#article-text h2 {width: auto; font-size: 24px; color: #4c4c4c; font-weight: 400;}
#article-text h3 {font-family: 'Kanit', sans-serif; display: inline-block; letter-spacing: 1px; font-size: 22px; line-height: 32px; color: #4c4c4c; font-weight: 500;}
#article-text p {font-family: 'Kanit', sans-serif; font-size: 16px; letter-spacing: 1px; line-height: 30px; color: #4c4c4c; font-weight: 300; margin: 20px 0;}
#article-text p a {color: #006297; font-weight: 500; transition: all ease .5s; text-decoration: none;}
#article-text p a:hover {color: #000000; transition: all ease .5s;}

#content {width: 100%; height:auto; position: relative;}
#content2 {width: 100%; height:auto; position: relative; background-color: #dfdfdf;}
#content figure {width: 100%!important; height: 500px; background-repeat: no-repeat; background-position: center; background-size: cover; position: relative;}
#content figure h1 {font-family: 'Kanit', sans-serif; font-size: 40px; color: #ffffff; font-weight: 500;}
#content figure h5 {font-family: 'Kanit', sans-serif; font-size: 30px; color: #ffffff; opacity: 0.5;}
#content > article {width: 100%; height: auto; padding: 50px 0; margin: auto;}
#content > article > h2 {font-family: 'Kanit', sans-serif; font-size: 26px; margin: 15px 0 0 0;}
#content > article > h3 {font-family: 'Kanit', sans-serif; font-size: 24px; border-bottom: 1px solid #636363; width: fit-content; color: #636363; font-weight: 400; margin: 30px 0 20px 0;}
#content > article > h4 {font-family: 'Kanit', sans-serif; font-size: 16px; color: #636363; font-weight: 500; margin: 10px 0;}
#content > article > p {font-family: 'Kanit', sans-serif; font-size: 14px; color: #666666; font-weight: 300; line-height: 24px; margin-top: 15px;}

.kvkk-liste {
    padding: 20px 0 30px 0;
    list-style-type: disc;
}

.kvkk-liste li {
    margin: 5px 0 5px 20px;
    font-weight: 300;
}

.kvkk-alfanumerik {
    padding: 20px 0 30px 0;
    list-style-type: lower-latin;
}

.kvkk-alfanumerik li {
    margin: 5px 0 5px 20px;
    font-weight: 300;
}

.kvkk-alfanumerik li::marker {
    font-weight: 700;
}

.kvkk-iletisim {
    width: 100%;
    display: block;
}

.kvkk-iletisim li {
    float: left;
}

.kvkk-iletisim li:nth-child(1n) {
    width: 30%;
}

.kvkk-iletisim li:nth-child(2n) {
    width: 70%;
}

.products > .prods {width: 100%; height: auto; max-height: auto; margin-bottom: 50px; display: table;}
.products > .prods > figure {width: 100%!important; max-width: 100%!important; height: 500px!important; max-height: 500px; float: none; background-size: cover!important; background-repeat: no-repeat; background-position: center; /*background-attachment: fixed;*/ margin: 0!important; padding: 0;}
.products > .prods > content {width: 100%; margin-left: 0; padding-top: 30px; display: table; float: none;}
.products > .prods > content > div {width: 100%; display: block; margin: 30px 0;}
.products > .prods > content > div > button:first-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #758a71; border: 1px solid #ffffff; transition: all ease .5s;}
.products > .prods > content > div > button:first-child:hover {background-color: #ffffff; color: #758a71; border: 1px solid #758a71; transition: all ease .5s;}
.products > .prods > content > div > button:last-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #1da800; border: 1px solid #ffffff; transition: all ease .5s;}
.products > .prods > content > div > button:last-child:hover {background-color: #ffffff; color: #1da800; border: 1px solid #1da800; transition: all ease .5s;}
.products > .prods > content h2, .products > .prods > content h3 {width: 100%; display: block;}

.services > .srv {width: 100%; height: auto; max-height: auto; margin-bottom: 50px; display: table;}
.services > .srv > figure {width: 100%!important; max-width: 100%!important; height: 500px!important; max-height: 500px; float: none; background-size: cover!important; background-repeat: no-repeat; background-position: center; /*background-attachment: fixed;*/ margin: 0!important; padding: 0;}
.services > .srv > content {width: 100%; margin-left: 0; padding-top: 30px; display: table; float: none;}
.services > .srv > content > div {width: 100%; display: block; margin: 30px 0;}
.services > .srv > content > div > button:first-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #758a71; border: 1px solid #ffffff; transition: all ease .5s;}
.services > .srv > content > div > button:first-child:hover {background-color: #ffffff; color: #758a71; border: 1px solid #758a71; transition: all ease .5s;}
.services > .srv > content > div > button:last-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #1da800; border: 1px solid #ffffff; transition: all ease .5s;}
.services > .srv > content > div > button:last-child:hover {background-color: #ffffff; color: #1da800; border: 1px solid #1da800; transition: all ease .5s;}
.services > .srv > content h2, .products > .prods > content h3 {width: 100%; display: block;}

.header-bg {background: #333333; width: 100%; height: 150px;}
.dealer-area {width: 100%; height: auto; margin: 50px auto;}
.dealer-area > h1 {width: auto; font-size: 40px; color: #4c4c4c; font-weight: 700; padding-left: 50px;}
.dealer-area > ul {width: 100%; height: auto; display: table; margin: 50px auto;}
.dealer-area > ul > li {width: 350px; height: 200px; float: left; margin-right: 50px; margin-bottom: 50px; transition: all ease .5s;}
.dealer-area > ul > li:hover {transition: all ease .5s; cursor: pointer;}
.dealer-area > ul > li:nth-child(3n) {margin-right: 0px;}
.dealer-area > ul > li:nth-child(-n+3) {background: #c67d7f;}
.dealer-area > ul > li:nth-child(-n+3):hover {background: #ffa3a6;}
.dealer-area > ul > li:nth-child(n+4) {background: #7d90c6;}
.dealer-area > ul > li:nth-child(n+4):hover {background: #94adf5;}
.dealer-area > ul > li:nth-child(n+7) {background: #1ca800;}
.dealer-area > ul > li:nth-child(n+7):hover {background: #28dd04;}
.dealer-area > ul > li:nth-child(9) {background: #1ca800;}
.dealer-area > ul > li:nth-child(9):hover {background: #28dd04;}
.dealer-area > ul > li > figure {width: 80px; height: 80px; margin: auto; margin-top: 30px;}
.dealer-area > ul > li > figure > img {width: 100%; height: 100%;}
.dealer-area > ul > li > h2 {text-align: center; font-size: 24px; font-weight: 300; color: #ffffff; margin-top: 20px;}

.contact {width: 100%; height: auto; margin: 50px auto;}
.contact > ul {width: 100%; height: auto; display: table;}
.contact > ul > li {float: left; width: 640px; padding: 20px;}
.contact > ul > li i {color: #c06c31; margin-right: 7px; vertical-align: middle;}
.contact > ul > li > h1 {font-size: 22px; color: #2e2e2e; line-height: 36px;}
.contact > ul > li > h2 {font-size: 18px; color: #2e2e2e; line-height: 36px; margin-top: 30px;}
.contact > ul > li > address {line-height: 36px;}
.contact > ul > li > address > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > address > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > .mail {line-height: 36px;}
.contact > ul > li > .mail > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > .mail > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > .phone {line-height: 36px;}
.contact > ul > li > .phone > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > .phone > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > form > div > span {display: block; font-size: 14px; color: #2e2e2e; margin: 10px 0;}
.contact > ul > li > form > div > input {width: 100%; height: 40px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e; background: #ffffff;}
.contact > ul > li > form > div > button {width: 150px; height: 40px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e; background: #ffffff; margin-top: 30px; transition: all ease .5s; cursor: pointer;}
.contact > ul > li > form > div > button:hover {background: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > form > div > textarea {width: 100%; height: 100px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e;}
}

@media screen and (min-width:1123px) and (max-width:1279px){
* {font-family: 'Kanit', sans-serif!important;}
.p-carousel-next, .p-carousel-prev{display: none!important;}
.mobile-top {display: none;}
.mobile-menu {display: none;}
.navbar {display: none;}

.duyurularPopup{width:320px; background: #fff; position: fixed; right:30px; bottom:30px; z-index: 11; -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); -moz-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.2); font-weight: 500;}
.duyurularPopup_govde:before{width:calc(100% - 10px); height:calc(100% - 10px); left:5px; top:5px; position: absolute; content:""; border:1px solid #dfdfdf; box-sizing: border-box; z-index: -1;}
.duyurularPopup h3{font-size:24px; font-weight: 600; margin-bottom: 15px; color: #364279;}
.duyurularPopup time{color:#757f94; display:block; margin-bottom: 5px; font-size:13px;}
.duyurularPopup a{color: #245092; display:block; font-size:16px;}
.duyurularPopup li{margin-bottom: 30px;}
.duyurularPopup li:last-child{margin-bottom: 0;}
.duyurularPopup a:Hover{color:#0099de;}
.duyurularPopup_govde{width:100%; padding:30px;}
#duyuru-kontrol{position: absolute; width:40px; height:40px; right:0; top:-20px; border-radius: 3px;}
#duyuru-kontrol img{float:left; width:20px; margin-top: 10px; margin-left: 10px;}
#duyuru-kontrol .gizle{background: #1da800; width:40px; height:40px; border-radius: 3px; cursor:pointer;}
#duyuru-kontrol .goster{background:#a61717; display:none; width:40px; height:40px; border-radius: 3px; cursor:pointer;}
#duyuru-kontrol .gizle:Hover{background: #a61717;}

header {width: 100%; height: 150px; position: absolute; left: 0; top: 0; z-index: 500!important; background-color: rgba(255, 255, 255, 0.8);}
.header-top {width: 100%; height: 30px; border-bottom: 1px solid #000000;}
.header-top > .inside {width: 100%; height: 30px; line-height: 30px; margin: auto;}
.header-top > .inside > .top-left {float: left;}
.header-top > .inside > .top-left > .top-dealer {width: auto; float: left; font-size: 12px;}
.header-top > .inside > .top-left > .top-dealer a {display: block; width: 100%; height: 100%; padding: 0 20px; background: #edbf9f; color: #000000; transition: all ease .5s; cursor: pointer;}
.header-top > .inside > .top-left > .top-dealer a:hover {background: #ffffff; transition: all ease .5s;}
.header-top > .inside > .top-left > .top-dealer a img {height: 14px; margin-right: 10px; vertical-align: sub;}
.header-top > .inside > .top-right {float: right;}
.header-top > .inside > .top-right > .social {width: auto; float: left; padding-left: 0; font-size: 13px;}
.header-top > .inside > .top-right > .social > a {color: #000000; transition: all ease .5s;}
.header-top > .inside > .top-right > .social > a i {margin: 0 5px;}
.header-top > .inside > .top-right > .social > a:hover {color: #ffffff; transition: all ease .5s;}

.header-bottom {width: 100%; height: 120px; margin: auto; padding: 0 20px;}
.header-bottom figure {width: 130px; height: 120px; float: left; line-height: 100px; position: relative;}
.header-bottom figure a {display: block; width: 130px; height: 120px; position: absolute;}
.header-bottom figure a img {width: 130px; height: 63px; margin-top: 30px;}
.header-bottom nav {width: auto; height: 120px; float: right;}
.header-bottom nav > ul {height: 120px; width: auto; display: flex; align-items: center;}
.header-bottom nav > ul > li {float: left; text-align: center; position: relative; z-index: 10;}
.header-bottom nav > ul > li > a {font-family: 'Kanit', sans-serif; letter-spacing: 2px; display: block; font-size: 16px; padding: 0 20px; color: #000000; line-height: 120px; transition: all ease .5s; font-weight: 300;}
.header-bottom nav > ul > li:hover a {color: #919191; transition: all ease .5s;}
.header-bottom nav > ul > li:last-child > a {margin-right: 0;}
.header-bottom nav > ul > li > ul {position: absolute; top: 80px; min-width: 250px; opacity: 0; visibility: hidden; -webkit-transition: opacity 600ms, visibility 600ms; transition: opacity 600ms, visibility 600ms;}
.header-bottom nav > ul > li:hover > ul {visibility: visible; opacity: 1; position: absolute; top: 80px; z-index: 10; font-family: 'Kanit', sans-serif; height: auto; min-width: 250px;}
.header-bottom nav > ul > li > ul > li {float: left; text-align: left; border-bottom: 1px solid #ececec; width: 100%; height: 45px; background: #ffffff;}
.header-bottom nav > ul > li > ul > li:hover a {color: #919191!important;}
.header-bottom nav > ul > li > ul > li > a {display: block; font-size: 14px; padding: 0 20px; color: #23222d!important; transition: all ease .5s; line-height: 45px; font-weight: 300; letter-spacing: 2px;}

.dealer-login {width: auto; height: auto; position: relative; background: #ffffff;}
.dealer-login > button {position: absolute; z-index: 999;}
.dealer-login > .inside {width: 700px; height: auto; margin: auto; padding: 30px 30px 0 30px; position: relative; background: #ffffff;}
.dealer-login > .inside > .dealer-head {width: 100%; height: 102px;}
.dealer-login > .inside > .dealer-head > figure {width: 180px; height: 102px; margin:auto;}
.dealer-login > .inside > .dealer-head > h1 {width: auto; height: auto; text-align: center; font-size: 32px; font-weight: 700; line-height: 45px;}
.dealer-login > .inside > .modal-body > form > ul {float: none; clear: both; width: 310px; margin: 50px auto; height: auto; display: table;}
.dealer-login > .inside > .modal-body > form > ul > li {float: left; height: 40px; margin-bottom: 20px;}
.dealer-login > .inside > .modal-body > form > ul > li > img {height: 40px;}
.dealer-login > .inside > .modal-body > form > ul > li:nth-child(1n) {width: 60px;}
.dealer-login > .inside > .modal-body > form > ul > li:nth-child(2n) {width: 250px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="text"] {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="password"] {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="text"]:focus {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > ul > li > input[type="password"]:focus {width: 100%; height: 50px; border-radius: 10px; font-family: 'Kanit', sans-serif; font-size: 14px; font-weight: 200; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px;}
.dealer-login > .inside > .modal-body > form > input[type="submit"] {float: left; cursor: pointer; width: 180px; height: 50px; border-radius: 10px; border: 1px solid #cfcfcf; background-color: #ffffff; padding: 0 25px; margin: auto; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > form > input[type="submit"]:hover {background-color: #cfcfcf; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > .dealer-links {width: fit-content; height: auto; float: right;}
.dealer-login > .inside > .modal-body > .dealer-links > a {color: #5574e4; display: block; transition: all ease .5s;}
.dealer-login > .inside > .modal-body > .dealer-links > a:hover {color: #cfcfcf; transition: all ease .5s;}

ngb-carousel {max-width: 700px; margin: 50px auto;}
ngb-carousel img {width: 100%; outline: none;}
ngb-carousel {width: inherit; height: inherit;}
.carousel-inner {overflow: visible;}
.carousel-item {display: flex !important; opacity: 0; visibility: hidden; transition: opacity 1.2s ease-in-out, visibility 1.2s; z-index: -1;}
.carousel-item.active{opacity: 1; visibility: visible; z-index: 10; }
.carousel-control-prev {z-index: 20;}
.carousel-control-next {z-index: 20;}
.carousel-indicators{z-index: 20;}

.mobilSl {display: none;}
.desktopSl {display: block;}
#slider {position: relative; width: 100%; height: 100vh!important; z-index: 0;/* background-image: url(../images/slide.jpg); background-repeat: no-repeat; background-position: center; background-size: cover;*/}
#slider .swiper-slide {margin-right: 0!important; position: relative; z-index: 0;}
.swiper-slide a {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
.slider-text {width: 40%; height: auto; position: absolute; z-index: 100; left: -45%; right: 0; bottom: 8vh;}
.slider-text h1 {font-family: 'Kanit', sans-serif; text-shadow: 3px 3px #000; font-size: 50px!important; line-height: 80px; font-weight: 800; color: #edbf9f;}
.slider-text h2 {font-family: 'Kanit', sans-serif; text-shadow: 3px 3px #000; font-size: 50px!important; line-height: 80px; font-weight: 800; color: #ffffff;}
.slider-text > a {position: relative; margin: auto;  display: block; width: 270px; height: initial; padding: 15px 50px; border: 1px solid #ffffff; color: #ffffff; font-size: 16px; font-weight: 300; font-style: italic; text-align: center; transition: all ease .5s;}
.slider-text > a > span {transition: all ease .5s; color: #ffffff;}
.slider-text > a:hover > span {transition: all ease .5s; color: #000000;}
.slider-text > a:hover {background: #ffffff; color: #f15d24; transition: all ease .5s;}
.slider-text2 > a {position: relative; margin: 30px auto;  display: block; width: 270px; height: initial; padding: 15px 50px; border: 1px solid #ffffff; color: #ffffff; font-size: 16px; font-weight: 300; font-style: italic; text-align: center; transition: all ease .5s;}
.slider-text2 > a > span {transition: all ease .5s; color: #ffffff;}
.slider-text2 > a:hover > span {transition: all ease .5s; color: #000000;}
.slider-text2 > a:hover {background: #ffffff; color: #f15d24; transition: all ease .5s;}
.swiper-button-next,.swiper-button-prev{background:none!important;}
.slider-text, .slider-text2 {display: inline-block; color:#fff; margin: auto; padding: 30px;}
#slider .swiper-slide{display:flex; align-items: center;}

.opd {display: block;}
.opm{display: none!important;}
.opt {display: none;}
.opt2 {display: none;}
.p-link {width: 15px; height: 15px; background-color: #757f94; margin: 0 7px; border-radius: 7px;}
#slider #pr_id_1 .p-carousel-item {flex: 1 0 100%!important;}
#our-products > .inside {width: 100%; height: auto; margin: auto; font-family: 'Kanit', sans-serif;}
#product-slider {overflow:hidden; width:100%; position:relative; height: 600px; margin: auto; padding-top: 50px;}
#product-slider > h1 {width: 100%; height: 80px; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; margin-bottom: 25px; padding-left: 0; background-image: url(/assets/images/modules-logo.png); background-repeat: no-repeat; background-position: bottom center; text-align: center;}
.carousel {height: 300px!important;}
.ps {box-shadow: none; margin: 0; background-color: #fbfbfb; text-align: center; width: 340px!important; height: 300px!important; position: relative; margin-right: 0px;}
.ps h2 {font-size: 14px; font-weight: 500; color: #484848; padding: 10px;}
.ps span {font-size: 14px; font-weight: 200; color: #595959; padding: 10px;}
.product-slide {position:relative}
.product-slide img {width: 300px!important; height: auto!important;}
.swiper-button-next,.swiper-button-prev {background:none!important;}
.product-slider-text {padding:10px; display: inline-block; color:#fff; margin: auto; margin-top: 20px;}
#slider .product-slide {display:flex; align-items: center;}
.urunler-item {margin: 10px!important; padding: 10px!important;}

#production {width: 100%; height: 580px; position: relative; overflow: hidden; display: table;}
#production > .inside {width: 100%; height: auto; position: relative; z-index: 999; margin: 50px auto; display: table;}
#production > .inside > h1 {text-align: right; width: 100%; height: 80px; text-align: center; font-size: 20px; color: #ffffff; font-weight: 700; letter-spacing: 2px; padding-right: 0px; background-image: url(/assets/images/modules-logo-white.png); background-repeat: no-repeat; background-position: bottom center;}
#production > .inside > ul {clear: both; width: 100%; height: auto; margin-top: initial; margin: 50px auto 0 auto;}
#production > .inside > ul > li {position: relative; width: 50%; height: 400px; margin: 0; padding: 20px; float: left; text-align: center;}
#production > .inside > ul > li > h2 {position: relative; display: block; width: 100%; text-align: center; top: 0; font-size: 18px; color: #ffffff; font-weight: 500;}
#production > .inside > ul > li:hover > h2 {top: 0px;}
#production > .inside > ul > li > span {position: relative; display: block; top: 0px; text-align: center; font-size: 12px; color: #ffffff; font-weight: 300;}
#production > .inside > ul > li:hover > span {top: 0px;}
#production > .inside > ul > li > img {opacity: 1; border-radius: 10px;}
#production > .inside > ul > li:hover > img {opacity: 1;}
.video-cover {position: absolute; z-index: 1; left: 0; top: 0; width: 100%; height: 100%; background-color: #8f0006; opacity: 0.7;}
video {top: 0; left: 0; min-width: 100%; min-height: 100%; width: auto; height: 100%; z-index: 0; position: absolute;}

#main-contact {width: 100%; height: 500px; position: relative;}
#main-contact > .inside {width: 100%; margin: 50px auto;}
#main-contact > #map {width: 100%; height: 400px;}
#main-contact > .inside > h1 {width: 100%; height: 80px; text-align: center; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; padding-left: 0; background-image: url(/assets/images/modules-logo.png); background-repeat: no-repeat; background-position: bottom center;}
#main-contact > .inside > span {display: none;}
#main-contact > iframe {height: 370px!important;}

#main-news {width: 100%; height: auto; position: relative; background: #ececec;}
#main-news > .inside {width: 100%; height: auto; position: relative; margin: auto; padding-top: 50px; text-align: center;}
#main-news > .inside > h1 {text-align: center; width: 100%; height: 80px; font-size: 20px; color: #90908f; font-weight: 700; letter-spacing: 2px; margin: auto; background: url(/assets/images/modules-logo.png) no-repeat bottom center;}
#main-news > .inside > ul {width: 100%; height: auto; display: table; margin-top: 50px;}
#main-news > .inside > ul > li {width: 33%; float: left; height: auto; display: table; position: relative; padding: 0 20px 30px 20px;}
#main-news > .inside > ul > li > a {width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 500;}
#main-news > .inside > ul > li > figure > img {width: 100%; height: 100%; border-radius: 10px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > figure > img {opacity: .7; transition: all ease .5s;}
#main-news > .inside > ul > li > div > h2 {font-family: 'Kanit', sans-serif; font-size: 18px; font-weight: 500; color: #000000; margin-top: 20px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > div > h2 {color: #a1a1a1; transition: all ease .5s;}
#main-news > .inside > ul > li > div > p {font-family: 'Kanit', sans-serif; font-size: 12px; font-weight: 200; color: #000000; margin-top: 20px; transition: all ease .5s;}
#main-news > .inside > ul > li:hover > div > p {color: #a1a1a1; transition: all ease .5s;}

footer {width: 100%; height: 150px; background: #2f2f2f;}

footer>.whatsapp {position: fixed; bottom: 50px; left: 50px; width: 75px; height: 75px; z-index: 999;}
footer>.whatsapp>button {background: none;}
footer>.whatsapp>button>img {width: 50px; height: inherit;}
footer>.whatsapp>.whatsapp-inside {position: absolute; width: 350px; padding: 15px 25px; bottom: 100px; border-radius: 20px; border: 1px solid #e9e9e9; background-color: #ffffff;}
footer>.whatsapp>.whatsapp-inside>a {display: block; color: #000000; font-size: 16px; font-weight: 300; line-height: 40px; height: 40px;}
footer>.whatsapp>.whatsapp-inside>a>img {width: 25px; height: 25px; vertical-align: text-bottom; margin-right: 10px;}
footer>.whatsapp>.whatsapp-inside>a:hover {color: #005312;}



footer > .inside {width: 100%; height: 150px; margin: auto;}
footer > .inside > ul {display: block; padding-top: 30px;}
footer > .inside > ul > li {flex: 1; line-height: 20px;}
footer > .inside > ul > li > a {display: inline-block; font-size: 14px; color: #ffffff; font-weight: 200; transition: all ease .5s;}
footer > .inside > ul > li > a:hover {color: #edbf9f; transition: all ease .5s;}
footer > .inside > ul > li > span {font-size: 14px; color: #ffffff; font-weight: 200; margin: 0 7px;}
footer > .inside > ul > li:first-child {text-align: center; margin-bottom: 20px;}
footer > .inside > ul > li:last-child {text-align: center;}

/* CONTENT */
main {background: #ffffff;}

.breadcrumb {width: auto; height: 110px; position: absolute; left: 0; right: 0; bottom: 0; margin: auto; background: none;}
#breadcrumb {width: auto; display: flex; align-items: center; position: absolute; bottom: 20px; justify-content: left;  font-family: 'Kanit', sans-serif; font-weight: 300; font-size: 16px;}
#breadcrumb > span {font-family: 'Kanit', sans-serif; width: 94%; height: 1px; background: #e5e5e5; margin: auto; display: block;}
#breadcrumb li a {font-family: 'Kanit', sans-serif; color: #ffffff; width: 100%; height: 100%; display: block;}
#breadcrumb li a span {font-family: 'Kanit', sans-serif; color: #ffffff;}
#breadcrumb li:last-child a {font-family: 'Kanit', sans-serif; color: #ffffff; width: 100%; height: 100%; display: block;}
#breadcrumb li:last-child span {font-family: 'Kanit', sans-serif; color: #ffffff;}
#breadcrumb li {font-family: 'Kanit', sans-serif; position: relative; float: left; display: flex; color: #ffffff; align-items: inherit;}
#breadcrumb li:before {content: ">"; padding: 0 7px; color: #ffffff;}
#breadcrumb li:first-child:before {content: ""; padding: 0;}
#breadcrumb li:last-child:before {color: #ffffff; padding-top: 3px;}
#breadcrumb li mark {background: none; color: #ffffff;}

#article-text {width: 100%; height: auto; margin: auto; padding: 50px 20px 30px 20px; display: table;}
#article-text figure {width: 100%!important; height: inherit; float: left; margin-right: 50px;}
#article-text figure img {border-radius: 10px;}
#article-text h1 {width: auto; font-size: 40px; color: #4c4c4c; font-weight: 700; background: url(/images/modules-logo) no-repeat left center; padding-left: 50px; float: left;}
#article-text h2 {width: auto; font-size: 24px; color: #4c4c4c; font-weight: 400;}
#article-text h3 {font-family: 'Kanit', sans-serif; display: inline-block; letter-spacing: 1px; font-size: 22px; line-height: 32px; color: #4c4c4c; font-weight: 500;}
#article-text p {font-family: 'Kanit', sans-serif; font-size: 16px; letter-spacing: 1px; line-height: 30px; color: #4c4c4c; font-weight: 300; margin: 20px 0;}
#article-text p a {color: #006297; font-weight: 500; transition: all ease .5s; text-decoration: none;}
#article-text p a:hover {color: #000000; transition: all ease .5s;}

#content {width: 100%; height:auto; position: relative;}
#content2 {width: 100%; height:auto; position: relative; background-color: #dfdfdf;}
#content figure {width: 100%; height: 500px; background-repeat: no-repeat; background-position: center; background-size: cover; position: relative;}
#content figure h1 {font-family: 'Kanit', sans-serif; font-size: 40px; color: #ffffff; font-weight: 500;}
#content figure h5 {font-family: 'Kanit', sans-serif; font-size: 30px; color: #ffffff; opacity: 0.5;}
#content > article {width: 1280px; height: auto; padding: 50px 0; margin: auto;}
#content > article > h2 {font-family: 'Kanit', sans-serif; font-size: 26px; margin: 15px 0 0 0;}
#content > article > h3 {font-family: 'Kanit', sans-serif; font-size: 24px; border-bottom: 1px solid #636363; width: fit-content; color: #636363; font-weight: 400; margin: 30px 0 20px 0;}
#content > article > h4 {font-family: 'Kanit', sans-serif; font-size: 16px; color: #636363; font-weight: 500; margin: 10px 0;}
#content > article > p {font-family: 'Kanit', sans-serif; font-size: 14px; color: #666666; font-weight: 300; line-height: 24px; margin-top: 15px;}

.kvkk-liste {
    padding: 20px 0 30px 0;
    list-style-type: disc;
}

.kvkk-liste li {
    margin: 5px 0 5px 20px;
    font-weight: 300;
}

.kvkk-alfanumerik {
    padding: 20px 0 30px 0;
    list-style-type: lower-latin;
}

.kvkk-alfanumerik li {
    margin: 5px 0 5px 20px;
    font-weight: 300;
}

.kvkk-alfanumerik li::marker {
    font-weight: 700;
}

.kvkk-iletisim {
    width: 100%;
    display: block;
}

.kvkk-iletisim li {
    float: left;
}

.kvkk-iletisim li:nth-child(1n) {
    width: 30%;
}

.kvkk-iletisim li:nth-child(2n) {
    width: 70%;
}

.products > .prods {width: 100%; height: auto; max-height: auto; margin-bottom: 50px; display: table;}
.products > .prods > figure {width: 100%!important; max-width: 100%!important; height: 500px!important; max-height: 500px; float: none; background-size: cover!important; background-repeat: no-repeat; background-position: center; /*background-attachment: fixed;*/ margin: 0!important; padding: 0;}
.products > .prods > content {width: 100%; margin-left: 0; padding-top: 30px; display: table; float: none;}
.products > .prods > content > div {width: 100%; display: block; margin: 30px 0;}
.products > .prods > content > div > button:first-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #758a71; border: 1px solid #ffffff; transition: all ease .5s;}
.products > .prods > content > div > button:first-child:hover {background-color: #ffffff; color: #758a71; border: 1px solid #758a71; transition: all ease .5s;}
.products > .prods > content > div > button:last-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #1da800; border: 1px solid #ffffff; transition: all ease .5s;}
.products > .prods > content > div > button:last-child:hover {background-color: #ffffff; color: #1da800; border: 1px solid #1da800; transition: all ease .5s;}
.products > .prods > content h2, .products > .prods > content h3 {width: 100%; display: block;}

.services > .srv {width: 100%; height: auto; max-height: auto; margin-bottom: 50px; display: table;}
.services > .srv > figure {width: 100%!important; max-width: 100%!important; height: 500px!important; max-height: 500px; float: none; background-size: cover!important; background-repeat: no-repeat; background-position: center; /*background-attachment: fixed;*/ margin: 0!important; padding: 0;}
.services > .srv > content {width: 100%; margin-left: 0; padding-top: 30px; display: table; float: none;}
.services > .srv > content > div {width: 100%; display: block; margin: 30px 0;}
.services > .srv > content > div > button:first-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #758a71; border: 1px solid #ffffff; transition: all ease .5s;}
.services > .srv > content > div > button:first-child:hover {background-color: #ffffff; color: #758a71; border: 1px solid #758a71; transition: all ease .5s;}
.services > .srv > content > div > button:last-child {padding: 10px 25px; margin: 0 50px 0 0; color: #ffffff; background-color: #1da800; border: 1px solid #ffffff; transition: all ease .5s;}
.services > .srv > content > div > button:last-child:hover {background-color: #ffffff; color: #1da800; border: 1px solid #1da800; transition: all ease .5s;}
.services > .srv > content h2, .products > .prods > content h3 {width: 100%; display: block;}

.header-bg {background: #333333; width: 100%; height: 150px;}
.dealer-area {width: 1280px; height: auto; margin: 50px auto;}
.dealer-area > h1 {width: auto; font-size: 40px; color: #4c4c4c; font-weight: 700; padding-left: 50px;}
.dealer-area > ul {width: 1200px; height: auto; display: table; margin: 50px auto;}
.dealer-area > ul > li {width: 350px; height: 200px; float: left; margin-right: 50px; margin-bottom: 50px; transition: all ease .5s;}
.dealer-area > ul > li:hover {transition: all ease .5s; cursor: pointer;}
.dealer-area > ul > li:nth-child(3n) {margin-right: 0px;}
.dealer-area > ul > li:nth-child(-n+3) {background: #c67d7f;}
.dealer-area > ul > li:nth-child(-n+3):hover {background: #ffa3a6;}
.dealer-area > ul > li:nth-child(n+4) {background: #7d90c6;}
.dealer-area > ul > li:nth-child(n+4):hover {background: #94adf5;}
.dealer-area > ul > li:nth-child(n+7) {background: #1ca800;}
.dealer-area > ul > li:nth-child(n+7):hover {background: #28dd04;}
.dealer-area > ul > li:nth-child(9) {background: #1ca800;}
.dealer-area > ul > li:nth-child(9):hover {background: #28dd04;}
.dealer-area > ul > li > figure {width: 80px; height: 80px; margin: auto; margin-top: 30px;}
.dealer-area > ul > li > figure > img {width: 100%; height: 100%;}
.dealer-area > ul > li > h2 {text-align: center; font-size: 24px; font-weight: 300; color: #ffffff; margin-top: 20px;}

.contact {width: 100%; height: auto; margin: 50px auto;}
.contact > ul {width: 100%; height: auto; display: table;}
.contact > ul > li {float: left; width: 50%; padding: 20px;}
.contact > ul > li i {color: #c06c31; margin-right: 7px; vertical-align: middle;}
.contact > ul > li > h1 {font-size: 22px; color: #2e2e2e; line-height: 36px;}
.contact > ul > li > h2 {font-size: 18px; color: #2e2e2e; line-height: 36px; margin-top: 30px;}
.contact > ul > li > address {line-height: 36px;}
.contact > ul > li > address > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > address > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > .mail {line-height: 36px;}
.contact > ul > li > .mail > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > .mail > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > .phone {line-height: 36px;}
.contact > ul > li > .phone > a {text-decoration: none; color: #2e2e2e; transition: all ease .5s;}
.contact > ul > li > .phone > a:hover {color: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > form > div > span {display: block; font-size: 14px; color: #2e2e2e; margin: 10px 0;}
.contact > ul > li > form > div > input {width: 100%; height: 40px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e; background: #ffffff;}
.contact > ul > li > form > div > button {width: 150px; height: 40px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e; background: #ffffff; margin-top: 30px; transition: all ease .5s; cursor: pointer;}
.contact > ul > li > form > div > button:hover {background: #cfcfcf; transition: all ease .5s;}
.contact > ul > li > form > div > textarea {width: 100%; height: 100px; border-radius: 10px; border: 1px solid #cfcfcf; padding: 10px; font-size: 12px; color: #2e2e2e;}
}