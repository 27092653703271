@media screen and (min-width:1280px) {
    * {
        font-family: 'Kanit', sans-serif;
    }
    .mobile-top {
        display: none;
    }
    .mobile-menu {
        display: none;
    }
    .navbar {
        display: none;
    }
    .duyurularPopup {
        width: 320px;
        background: #fff;
        position: fixed;
        right: 30px;
        bottom: 30px;
        z-index: 11;
        -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.2);
        font-weight: 500;
    }
    .duyurularPopup_govde:before {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        left: 5px;
        top: 5px;
        position: absolute;
        content: "";
        border: 1px solid #dfdfdf;
        box-sizing: border-box;
        z-index: -1;
    }
    .duyurularPopup h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #364279;
    }
    .duyurularPopup time {
        color: #757f94;
        display: block;
        margin-bottom: 5px;
        font-size: 13px;
    }
    .duyurularPopup a {
        color: #245092;
        display: block;
        font-size: 16px;
    }
    .duyurularPopup li {
        margin-bottom: 30px;
    }
    .duyurularPopup li:last-child {
        margin-bottom: 0;
    }
    .duyurularPopup a:Hover {
        color: #0099de;
    }
    #duyuru-kontrol {
        position: absolute;
        width: 40px;
        height: 40px;
        right: 0;
        top: -20px;
        border-radius: 3px;
    }
    #duyuru-kontrol img {
        float: left;
        width: 20px;
        margin-top: 10px;
        margin-left: 10px;
    }
    #duyuru-kontrol .gizle {
        background: #1da800;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        cursor: pointer;
    }
    #duyuru-kontrol .goster {
        background: #a61717;
        display: none;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        cursor: pointer;
    }
    #duyuru-kontrol .gizle:Hover {
        background: #a61717;
    }
    .duyurularPopup_govde {
        width: 100%;
        padding: 30px;
    }
    header {
        width: 100%;
        height: 150px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 500!important;
        background-color: rgba(255, 255, 255, 0.8);
    }
    .header-top {
        width: 100%;
        height: 30px;
        border-bottom: 1px solid #000000;
    }
    .header-top>.inside {
        width: 1280px;
        height: 30px;
        line-height: 30px;
        margin: auto;
    }
    .header-top>.inside>.top-left {
        float: left;
    }
    .header-top>.inside>.top-left>.top-dealer {
        width: auto;
        float: left;
        font-size: 12px;
    }
    .header-top>.inside>.top-left>.top-dealer a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        /*background: #d6533c;*/
        color: #000000;
        transition: all ease .5s;
        cursor: pointer;
    }
    .header-top>.inside>.top-left>.top-dealer a:hover {
        background: #ffffff;
        transition: all ease .5s;
    }
    .header-top>.inside>.top-left>.top-dealer a img {
        height: 14px;
        margin-right: 10px;
        vertical-align: sub;
    }
  
    .header-top>.inside>.top-right {
        float: right;
    }

    .header-top>.inside>.top-right>.social {
        width: auto;
        float: left;
        padding-left: 20px;
        font-size: 13px;
    }
    .header-top>.inside>.top-right>.social>a {
        color: #000000;
        transition: all ease .5s;
    }
    .header-top >.inside>.top-right>.social>a i {
        margin: 0 5px;
    }
    .header-top>.inside>.top-right>.social>a:hover {
        color: #a39797;
        transition: all ease .5s;
    }
    .header-bottom {
        width: 1280px;
        height: 120px;
        margin: auto;
    }
    .header-bottom figure {
        width: 150px;
        height: 120px;
        float: left;
        line-height: 100px;
        position: relative;
    }
    .header-bottom figure a {
        display: block;
        width: 150px;
        height: 120px;
        position: absolute;
    }
    .header-bottom figure a img {
        width: 150px;
        height: 73px;
        margin-top: 24px;
    }
    .header-bottom nav {
        width: auto;
        height: 120px;
        float: right;
    }
    .header-bottom nav>ul {
        height: 120px;
        width: auto;
        display: flex;
        align-items: center;
    }
    .header-bottom nav>ul>li {
        float: left;
        text-align: center;
        position: relative;
        z-index: 10;
    }
    .header-bottom nav>ul>li>a {
        font-family: 'Kanit', sans-serif;
        letter-spacing: 2px;
        display: block;
        font-size: 18px;
        padding: 0 20px;
        color: #000000;
        line-height: 120px;
        transition: all ease .5s;
        font-weight: 300;
    }
    .header-bottom nav>ul>li:hover a {
        color: #a39797;
        transition: all ease .5s;
    }
    .header-bottom nav>ul>li:last-child>a {
        margin-right: 0;
    }
    .header-bottom nav>ul>li>ul {
        position: absolute;
        top: 80px;
        min-width: 250px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 600ms, visibility 600ms;
        transition: opacity 600ms, visibility 600ms;
    }
    .header-bottom nav>ul>li:hover>ul {
        visibility: visible;
        opacity: 1;
        position: absolute;
        top: 80px;
        z-index: 10;
        font-family: 'Kanit', sans-serif;
        height: auto;
        min-width: 250px;
    }
    .header-bottom nav>ul>li>ul>li {
        float: left;
        text-align: left;
        border-bottom: 1px solid #ececec;
        width: 100%;
        height: 45px;
        background: #ffffff;
    }
    .header-bottom nav>ul>li>ul>li:hover a {
        color: #a39797!important;
    }
    .header-bottom nav>ul>li>ul>li>a {
        display: block;
        font-size: 14px;
        padding: 0 20px;
        color: #000000!important;
        transition: all ease .5s;
        line-height: 45px;
        font-weight: 300;
        letter-spacing: 2px;
    }
    .dealer-login {
        width: auto;
        height: auto;
        position: relative;
        background: #ffffff;
    }
    .dealer-login>.inside {
        width: 700px;
        height: auto;
        min-height: 500px;
        margin: auto;
        display: table;
        padding: 30px 30px 0 30px;
        position: relative;
        background: #ffffff;
    }
    .dealer-login>.inside>button {
        float: left;
        width: 200px;
        height: 60px;
        background-color: #1ca800;
        background-image: url(/assets/images/offer-wh.svg);
        background-repeat: no-repeat;
        background-position: 15px 13px;
        background-size: 15%;
        color: #ffffff;
        border: 1px solid #ffffff;
        transition: all ease .5s;
    }
    .dealer-login>.inside>button:hover {
        background-color: #ffffff;
        color: #1ca800;
        background-image: url(/assets/images/offer-gr.svg);
        background-repeat: no-repeat;
        background-position: 15px 13px;
        background-size: 15%;
        border: 1px solid #1ca800;
        transition: all ease .5s;
    }
    .dealer-login>.inside .dealer-head {
        width: 100%;
        height: 102px;
    }
    .dealer-login>.inside .dealer-head>figure {
        width: 180px;
        height: 102px;
        margin: auto;
    }
    .dealer-login>.inside .dealer-head>h1 {
        width: auto;
        height: auto;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        line-height: 45px;
    }
    .dealer-login>.inside>ul {
        float: none;
        clear: both;
        width: 310px;
        margin: 50px auto;
        height: auto;
        display: table;
    }
    .dealer-login>.inside>ul li {
        float: left;
        height: 40px;
        margin-bottom: 20px;
    }
    .dealer-login>.inside>ul li img {
        height: 40px;
    }
    .dealer-login>.inside>ul li:nth-child(1n) {
        width: 55px;
    }
    .dealer-login>.inside ul li:nth-child(2n) {
        width: 250px;
    }
    .dealer-login>.inside ul li input[type="text"] {
        width: 250px;
        height: 50px;
        border-radius: 10px;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        font-weight: 200;
        border: 1px solid #cfcfcf;
        background-color: #ffffff;
        padding: 0 25px;
    }
    .dealer-login>.inside ul li p-inputnumber span input {
        width: 250px!important;
        height: 50px!important;
        border-radius: 10px!important;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        font-weight: 200;
        border: 1px solid #cfcfcf;
        background-color: #ffffff;
        padding: 0 25px;
    }
    .dealer-login>.inside ul li input[type="password"] {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        font-weight: 200;
        border: 1px solid #cfcfcf;
        background-color: #ffffff;
        padding: 0 25px;
    }
    .dealer-login>.inside ul li input[type="text"]:focus {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        font-weight: 200;
        border: 1px solid #cfcfcf;
        background-color: #ffffff;
        padding: 0 25px;
    }
    .dealer-login>.inside ul li input[type="password"]:focus {
        width: 100%;
        height: 50px;
        border-radius: 10px;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        font-weight: 200;
        border: 1px solid #cfcfcf;
        background-color: #ffffff;
        padding: 0 25px;
    }
    .dealer-login>.inside>.error-msg {
        font-size: 26px;
        color: #ff0000;
        display: block;
        width: 100%;
        height: 100px;
        clear: both;
        line-height: 100px;
        text-align: center;
    }
    .dealer-login>.inside input[type="submit"] {
        float: left;
        cursor: pointer;
        width: 180px;
        height: 50px;
        border-radius: 10px;
        border: 1px solid #cfcfcf;
        background-color: #ffffff;
        padding: 0 25px;
        margin: auto;
        transition: all ease .5s;
    }
    .dealer-login>.inside input[type="submit"]:hover {
        background-color: #cfcfcf;
        transition: all ease .5s;
    }
    .dealer-login>.inside>.dealer-links {
        width: fit-content;
        height: auto;
        float: right;
    }
    .dealer-login>.inside>.dealer-links>a {
        color: #5574e4;
        display: block;
        transition: all ease .5s;
    }
    .dealer-login>.inside>.dealer-links>a:hover {
        color: #cfcfcf;
        transition: all ease .5s;
    }
    
    ngb-carousel {
        max-width: 700px;
        margin: 50px auto;
    }
    ngb-carousel img {
        width: 100%;
        outline: none;
    }
    ngb-carousel {
        width: inherit;
        height: inherit;
    }
    .carousel-inner {
        overflow: visible;
    }
    .carousel-item {
        display: flex !important;
        opacity: 0;
        visibility: hidden;
        transition: opacity 1.2s ease-in-out, visibility 1.2s;
        z-index: -1;
    }
    .carousel-item.active {
        opacity: 1;
        visibility: visible;
        z-index: 10;
    }
    .carousel-control-prev {
        z-index: 20;
    }
    .carousel-control-next {
        z-index: 20;
    }
    .carousel-indicators {
        z-index: 20;
    }
    .mobilSl {display: none!important  ;}
    .desktopSl {display: block!important;}
    #slider {
        position: relative;
        width: 100%;
        height: 100vh!important;
        z-index: 0;
        /* background-image: url(../images/slide.jpg); background-repeat: no-repeat; background-position: center; background-size: cover;*/
    }
    #slider .swiper-slide {
        margin-right: 0!important;
        position: relative;
        z-index: 0;
    }
    .swiper-slide a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .slider-text {
        width: 700px;
        height: auto;
        position: absolute;
        z-index: 100;
        left: -500px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 8vh;
        /*background-color: rgba(0, 0, 0, 0.5);*/
    }

    #slider p-carousel .p-carousel .p-carousel-content .p-carousel-prev {
        display: none;
    }
    #slider p-carousel .p-carousel .p-carousel-content .p-carousel-next {
        display: none;
    }

    .slider-text h1 {
        font-family: 'Kanit', sans-serif;
        text-shadow: 3px 3px #000;
        font-size: 60px!important;
        line-height: 90px!important;
        font-weight: 800!important;
        color: #edbf9f;
    }
    .slider-text h2 {
        font-family: 'Kanit', sans-serif;
        text-shadow: 3px 3px #000;
        font-size: 60px!important;
        font-weight: 800!important;
        color: #ffffff;
        margin-left: 200px;
    }
    .slider-text>a {
        position: relative;
        margin: auto;
        display: block;
        width: 270px;
        height: initial;
        padding: 15px 50px!important;
        border: 1px solid #ffffff;
        color: #ffffff;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        text-align: center;
        transition: all ease .5s;
    }
    .slider-text>a>span {
        transition: all ease .5s;
        color: #ffffff;
    }
    .slider-text>a:hover>span {
        transition: all ease .5s;
        color: #000000;
    }
    .slider-text>a:hover {
        background: #ffffff;
        color: #f15d24;
        transition: all ease .5s;
    }
    .slider-text2>a {
        position: relative;
        margin: 30px auto;
        display: block;
        width: 270px;
        height: initial;
        padding: 15px 50px;
        border: 1px solid #ffffff;
        color: #ffffff;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        text-align: center;
        transition: all ease .5s;
    }
    .slider-text2>a>span {
        transition: all ease .5s;
        color: #ffffff;
    }
    .slider-text2>a:hover>span {
        transition: all ease .5s;
        color: #000000;
    }
    .slider-text2>a:hover {
        background: #ffffff;
        color: #f15d24;
        transition: all ease .5s;
    }
    .swiper-button-next,
    .swiper-button-prev {
        background: none!important;
    }
    .slider-text,
    .slider-text2 {
        padding: 10px;
        display: inline-block;
        color: #fff;
        margin: auto;
        padding: 50px;
    }
    #slider .swiper-slide {
        display: flex;
        align-items: center;
    }
    .opd {display: block;}
    .opm{
        display: none!important;
    }
    .opt {
        display: none!important;
    }
    .opt2 {
        display: none!important;
    }
    #our-products {
        display: block;
        width: 100%;
        height: 600px;
    }
    #our-products>.inside {
        width: 1280px;
        height: 600px;
        margin: auto;
        font-family: 'Kanit', sans-serif;
    }
    #product-slider {
        overflow: hidden;
        width: 1280px;
        position: relative;
        height: 600px;
        margin: auto;
        padding-top: 50px;
    }
   
    #product-slider>h1 {
        width: 280px;
        height: 35px;
       
        font-size: 31px;
        color: #90908f;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 30px;
        padding-left: 80px;
        background-image: url(/assets/images/modules-logo.png);
        background-repeat: no-repeat;
        background-position: left center;
    }
    #product-slider .swiper-slide {
        width: 300px!important;
        height: 280px;
        position: relative;
        margin-right: 0px;
    }
    #product-slider .swiper-wrapper {
        margin-top: 50px;
    }

    #product-slider p-carousel .p-carousel .p-carousel-content .p-carousel-prev {
        position: absolute;
        right: 30px;
        top: 65px;
    }

    #product-slider p-carousel .p-carousel .p-carousel-content .p-carousel-next {
        position: absolute;
        right: 0px;
        top: 65px;
    }
    .p-link {width: 20px; height: 30px;}

    .p-link .pi-chevron-left:before {
        content: "<";
    }
    .p-link .pi-chevron-right:before {
        content: ">";
    }
    .p-carousel-indicators {display: none!important;}
    
    .ps {
        box-shadow: 0 0 3px;
        margin: 5px 9px;
        background-color: #fbfbfb;
        text-align: center;
        width: 300px!important;
        height: 295px!important;
        position: relative;
        margin-right: 0px;
    }
    .ps h2 {
        font-size: 14px;
        font-weight: 500;
        color: #484848;
    }
    .ps span {
        font-size: 14px;
        font-weight: 200;
        color: #595959;
    }
    .product-slide {
        position: relative
    }
    .product-slide img {
        width: 300px;
        height: 200px!important;
    }
    .swiper-button-next,
    .swiper-button-prev {
        background: none!important;
    }
    .product-slider-text {
        padding: 10px;
        display: inline-block;
        color: #fff;
        margin: auto;
        margin-top: 20px;
    }
    #slider .product-slide {
        display: flex;
        align-items: center;
    }
    #production {
        width: 100%;
        height: 550px;
        position: relative;
        overflow: hidden;
    }
    #production>.inside {
        width: 1280px;
        height: 500px;
        position: relative;
        z-index: 999;
        margin: 50px auto;
    }
    #production>.inside>h1 {
        text-align: right;
        width: 100%;
        font-size: 30px;
        color: #ffffff;
        font-weight: 700;
        letter-spacing: 2px;
        padding-right: 50px;
        background-image: url(/assets/images/modules-logo-white.png);
        background-repeat: no-repeat;
        background-position: right center;
    }
    #production>.inside>ul {
        clear: both;
        width: 100%;
        height: 300px;
        margin-top: 100px;
    }
    #production>.inside>ul>li {
        position: relative;
        width: 300px;
        height: 400px;
        margin: 0 10px;
        float: left;
        text-align: center;
    }
    #production>.inside>ul>li>h2 {
        position: absolute;
        display: block;
        width: 100%;
        text-align: center;
        top: 50px;
        font-size: 22px;
        color: #ffffff;
        font-weight: 500;
        transition: all ease .5s;
    }
    #production>.inside>ul>li:hover>h2 {
        top: -50px;
        transition: all ease .5s;
    }
    #production>.inside>ul>li>span {
        position: absolute;
        display: block;
        top: 90px;
        text-align: center;
        font-size: 12px;
        color: #ffffff;
        font-weight: 300;
        transition: all ease .5s;
    }
    #production>.inside>ul>li:hover>span {
        top: 250px;
        transition: all ease .5s;
    }
    #production>.inside>ul>li>img {
        opacity: 0;
        transition: all ease .5s;
        border-radius: 10px;
    }
    #production>.inside>ul>li:hover>img {
        opacity: .8;
        transition: all ease .5s;
    }
    .video-cover {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #8f0006;
        opacity: 0.7;
    }
    video {
        top: -40%;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        position: absolute;
    }
    #main-contact {
        width: 100%;
        height: 517px;
        position: relative;
    }
    #main-contact>.inside {
        width: 1280px;
        margin: 50px auto;
    }
    #main-contact>#map {
        width: 100%;
        height: 400px;
    }
    #main-contact>.inside>h1 {
        width: 280px;
        height: 35px;
        font-size: 31px;
        color: #90908f;
        font-weight: 700;
        letter-spacing: 2px;
        margin-bottom: 30px;
        padding-left: 80px;
        background-image: url(/assets/images/modules-logo.png);
        background-repeat: no-repeat;
        background-position: left center;
    }
    #main-contact>.inside>span {
        font-size: 16px;
        color: #d4b5a3;
        font-weight: 300;
        letter-spacing: 2px;
        padding-left: 50px;
    }
    #main-contact>iframe {
        height: 380px!important;
    }
    #main-news {
        width: 100%;
        height: 600px;
        position: relative;
        background: #ececec;
    }
    #main-news>.inside {
        width: 1280px;
        height: 550px;
        position: relative;
        margin: auto;
        padding-top: 50px;
        text-align: center;
    }
    #main-news>.inside>h1 {
        text-align: center;
        height: 35px;
        width: 485px;
        font-size: 31px;
        color: #90908f;
        font-weight: 700;
        letter-spacing: 2px;
        margin: auto;
        background: url(/assets/images/modules-logo.png) no-repeat 3% 50%;
    }
    #main-news>.inside>ul {
        width: 1280px;
        height: 400px;
        display: flex;
        margin-top: 50px;
    }
    #main-news>.inside>ul>li {
        width: auto;
        height: 400px;
        flex: 1;
        position: relative;
        padding: 0 20px;
    }
    #main-news>.inside>ul>li>a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 500;
    }
    #main-news>.inside>ul>li>figure {position: relative;}
    #main-news>.inside>ul>li>figure>img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        transition: all ease .5s;
    }
    #main-news>.inside>ul>li>figure>time {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: #000000;
        opacity: .6;
        color: #ffffff;
        padding: 5px 10px;
    }
    #main-news>.inside>ul>li:hover>figure>img {
        opacity: .7;
        transition: all ease .5s;
    }
    #main-news>.inside>ul>li>div>h2 {
        font-family: 'Kanit', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
        margin-top: 20px;
        transition: all ease .5s;
    }
    #main-news>.inside>ul>li:hover>div>h2 {
        color: #a1a1a1;
        transition: all ease .5s;
    }
    #main-news>.inside>ul>li>div>p {
        font-family: 'Kanit', sans-serif;
        font-size: 12px;
        font-weight: 200;
        color: #000000;
        margin-top: 20px;
        transition: all ease .5s;
    }
    #main-news>.inside>ul>li:hover>div>p {
        color: #a1a1a1;
        transition: all ease .5s;
    }
    footer {
        width: 100%;
        height: 150px;
        background: #2f2f2f;
        position: relative;
    }
    footer>.whatsapp {position: fixed; bottom: 50px; left: 50px; width: 75px; height: 75px; z-index: 999;}
    footer>.whatsapp>button {background: none;}
    footer>.whatsapp>button>img {width: 50px; height: inherit;}
    footer>.whatsapp>.whatsapp-inside {position: absolute; width: 400px; padding: 15px 25px; bottom: 100px; border-radius: 20px; border: 1px solid #e9e9e9; background-color: #ffffff;}
    footer>.whatsapp>.whatsapp-inside>a {display: block; color: #000000; font-size: 16px; font-weight: 300; line-height: 40px; height: 40px;}
    footer>.whatsapp>.whatsapp-inside>a>img {width: 25px; height: 25px; vertical-align: text-bottom; margin-right: 10px;}
    footer>.whatsapp>.whatsapp-inside>a:hover {color: #005312;}




    footer>.inside {
        width: 1280px;
        height: 150px;
        margin: auto;
    }
    footer>.inside>ul {
        display: flex;
    }
    footer>.inside>ul>li {
        flex: 1;
        line-height: 150px;
    }
    footer>.inside>ul>li>a {
        font-size: 12px;
        color: #ffffff;
        font-weight: 200;
        transition: all ease .5s;
    }
    footer>.inside>ul>li>span>a>img {
        height: 16px!important;
        vertical-align: text-top;
        margin-right: 5px;
    }
    footer>.inside>ul>li>a:hover {
        color: #edbf9f;
        transition: all ease .5s;
    }
    footer>.inside>ul>li>span {
        font-size: 12px;
        color: #ffffff;
        font-weight: 200;
        margin: 0 7px;
    }
    footer>.inside>ul>li:first-child {
        text-align: left;
    }
    footer>.inside>ul>li:nth-child(2) {
        text-align: center;
    }
    footer>.inside>ul>li:last-child {
        text-align: right;
    }
    /* CONTENT */
    main {
        background: #ffffff;
    }
    .breadcrumb {
        width: 1280px;
        height: 110px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: none;
    }
    #breadcrumb {
        width: 1280px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 20px;
        justify-content: left;
        font-family: 'Kanit', sans-serif;
        font-weight: 300;
        font-size: 16px;
    }
    #breadcrumb>span {
        font-family: 'Kanit', sans-serif;
        width: 94%;
        height: 1px;
        background: #e5e5e5;
        margin: auto;
        display: block;
    }
    #breadcrumb li a {
        font-family: 'Kanit', sans-serif;
        color: #ffffff;
        width: 100%;
        height: 100%;
        display: block;
    }
    #breadcrumb li a span {
        font-family: 'Kanit', sans-serif;
        color: #ffffff;
    }
    #breadcrumb li:last-child a {
        font-family: 'Kanit', sans-serif;
        color: #ffffff;
        width: 100%;
        height: 100%;
        display: block;
    }
    #breadcrumb li:last-child span {
        font-family: 'Kanit', sans-serif;
        color: #ffffff;
    }
    #breadcrumb li {
        font-family: 'Kanit', sans-serif;
        position: relative;
        float: left;
        display: flex;
        color: #ffffff;
        align-items: inherit;
    }
    #breadcrumb li:before {
        content: ">";
        padding: 0 7px;
        color: #ffffff;
    }
    #breadcrumb li:first-child:before {
        content: "";
        padding: 0;
    }
    #breadcrumb li:last-child:before {
        color: #ffffff;
        padding-top: 3px;
    }
    #breadcrumb li mark {
        background: none;
        color: #ffffff;
    }
    #article-text {
        width: 1280px;
        height: auto;
        margin: auto;
        padding: 50px 0 30px 0;
        display: table;
    }
    #article-text figure {
        width: 50%;
        height: inherit;
        float: left;
        margin-right: 50px;
    }
    #article-text figure img {
        border-radius: 10px;
    }
    #article-text h1 {
        width: auto;
        font-size: 40px;
        color: #4c4c4c;
        font-weight: 700;
        background: url(/images/modules-logo) no-repeat left center;
        padding-left: 50px;
        float: left;
    }
    #article-text h2 {
        width: 100%;
        display: block;
        font-size: 24px;
        color: #4c4c4c;
        font-weight: 400;
    }
    #article-text h3 {
        width: 100%;
        display: block;
        font-family: 'Kanit', sans-serif;
        display: inline-block;
        letter-spacing: 1px;
        font-size: 22px;
        line-height: 32px;
        color: #4c4c4c;
        font-weight: 500;
    }
    #article-text p {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 32px;
        color: #4c4c4c;
        font-weight: 300;
        margin: 20px 0;
    }
    #article-text p a {
        color: #006297;
        font-weight: 500;
        transition: all ease .5s;
        text-decoration: none;
    }
    #article-text p a:hover {
        color: #000000;
        transition: all ease .5s;
    }
    #content {
        width: 100%;
        height: auto;
        position: relative;
    }
    #content2 {
        width: 100%;
        height: auto;
        position: relative;
        background-color: #dfdfdf;
    }
    #content figure {
        width: 100%;
        height: 500px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        background-color: #000000!important;
    }
    #content figure h1 {
        font-family: 'Kanit', sans-serif;
        font-size: 40px;
        color: #ffffff;
        font-weight: 500;
    }
    #content figure h5 {
        font-family: 'Kanit', sans-serif;
        font-size: 30px;
        color: #ffffff;
        opacity: 0.5;
    }
    #content>article {
        width: 1280px;
        height: auto;
        padding: 50px 0;
        margin: auto;
    }
    #content>article>h2 {
        font-family: 'Kanit', sans-serif;
        font-size: 26px;
        margin: 15px 0 0 0;
    }
    #content>article>h3 {
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
        border-bottom: 1px solid #636363;
        width: fit-content;
        color: #636363;
        font-weight: 400;
        margin: 30px 0 20px 0;
    }
    #content>article>h4 {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        color: #636363;
        font-weight: 500;
        margin: 10px 0;
    }
    #content>article>p {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        color: #666666;
        font-weight: 300;
        line-height: 24px;
        margin-top: 15px;
    }

    .kvkk-liste {
        padding: 20px 0 30px 0;
        list-style-type: disc;
    }

    .kvkk-liste li {
        margin: 5px 0 5px 20px;
        font-weight: 300;
    }

    .kvkk-alfanumerik {
        padding: 20px 0 30px 0;
        list-style-type: lower-latin;
    }

    .kvkk-alfanumerik li {
        margin: 5px 0 5px 20px;
        font-weight: 300;
    }
    
    .kvkk-alfanumerik li::marker {
        font-weight: 700;
    }

    .kvkk-iletisim {
        width: 100%;
        display: block;
    }

    .kvkk-iletisim li {
        float: left;
    }

    .kvkk-iletisim li:nth-child(1n) {
        width: 30%;
    }

    .kvkk-iletisim li:nth-child(2n) {
        width: 70%;
    }

    .products>.prods {
        width: 100%;
        height: auto;
        max-height: 500px;
        margin: 50px;
        display: table;
    }
    .products>.prods>figure {
        max-width: 500px!important;
        height: 300px!important;
        float: left;
        background-size: cover!important;
        background-repeat: no-repeat;
        background-position: center;
        /*background-attachment: fixed;*/
        margin: 0!important;
        padding: 0;
    }
    .products>.prods>content {
        width: 700px;
        margin-left: 35px;
        float: left;
    }
    .products>.prods>content>div {
        width: auto;
        display: block;
        margin: 30px 0;
    }
    .products>.prods>div {
        width: auto;
        display: table;
        margin: 30px 35px;
        float: left;
    }
    .products>.prods>div>a:first-child {
        padding: 10px 35px;
        margin: 0 50px 0 0;
        color: #ffffff;
        background-color: #758a71;
        border: 1px solid #ffffff;
        transition: all ease .5s;
        float: right;
    }
    .products>.prods>div>a:first-child:hover {
        background-color: #ffffff;
        color: #758a71;
        border: 1px solid #758a71;
        transition: all ease .5s;
    }
    .products>.prods>content h2,
    .products>.prods>content h3 {
        width: 100%;
    }
    .services {
        border: none!important;
        padding: 10px;
        position: relative;
    }
    .services>.srv {
        width: 100%;
        height: auto;
        max-height: 500px;
        margin-bottom: 50px;
        display: table;
    }
    .services>.srv>figure {
        max-width: 500px!important;
        height: 300px!important;
        float: left;
        background-size: cover!important;
        background-repeat: no-repeat;
        background-position: center;
        /*background-attachment: fixed;*/
        margin: 0!important;
        padding: 0;
    }
    .services>.srv>content {
        width: 700px;
        margin-left: 35px;
        float: left;
    }
    .services>.srv>div {
        width: auto;
        display: table;
        margin: 30px 35px;
        float: left;
    }
    .services>.srv>div>a:first-child {
        padding: 10px 35px;
        margin: 0 50px 0 0;
        color: #ffffff;
        background-color: #758a71;
        border: 1px solid #ffffff;
        transition: all ease .5s;
    }
    .services>.srv>div>a:first-child:hover {
        background-color: #ffffff;
        color: #758a71;
        border: 1px solid #758a71;
        transition: all ease .5s;
    }
    .services>.srv>content>h2 {
        width: auto;
        font-size: 24px;
        color: #4c4c4c;
        font-weight: 400;
        display: inline-block;
    }
    .services>.srv>content h2,
    .products>.prods>content h3 {
        width: 100%;
    }
    .prods>a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .makine-parkuru {width: 100%; height: 100%;}
    .makine-parkuru tr {border: 1px solid #000000;}
    .makine-parkuru tr td {padding: 15px 7px;}
    .makine-parkuru tr td {vertical-align: middle; border-left: 1px solid #000000;}



    .organization-schema {
        width: 100%;
        height: 100%;
    }

    .organization-schema tr {border: 1px solid #000000;}
    .organization-schema tr th {padding: 15px 7px; font-weight: 700; font-size: 22px; border-left: 1px solid #000000;}
    .organization-schema tr td {padding: 15px 7px; vertical-align: middle; border-left: 1px solid #000000;}
    .organization-schema tr td a {color: #9b0000; transition: all ease .5s;}
    .organization-schema tr td a:hover {color: #000000; transition: all ease .5s;}
    
    /*.organzation-schema li {
        width: 250px;
        height: 375px;
        margin-bottom: 30px;
        position: relative;
        float: left;
        text-align: center;
        margin-right: 70px;
        transition: all ease .5s;
        box-shadow: 0px;
    }
    .organzation-schema li:hover {
        transition: all ease .5s;
        box-shadow: 1px 1px 1px 1px rgb(146, 146, 146);
    }
    .organzation-schema li:nth-child(4n) {
        margin-right: 0;
    }
    .organzation-schema li figure {
        display: block;
        width: 250px!important;
        height: auto!important;
        margin: 0!important;
        padding: 0!important;
    }
    .organzation-schema li figure img {
        width: 100%!important;
        height: auto;
        margin: 0!important;
        padding: 0!important;
    }
    .organzation-schema li h2 {
        width: 100%;
        font-size: 20px!important;
        color: #000;
        font-weight: 600!important;
        line-height: 26px!important;
        margin: 0!important;
    }
    .organzation-schema li h3 {
        width: 100%;
        font-size: 16px!important;
        color: #000;
        font-weight: 300!important;
    }
    .organzation-schema li a {
        display: block;
        width: 100%;
        font-size: 14px;
        color: #000;
        transition: all ease .5s;
    }
    .organzation-schema li a:hover {
        color: #bd0000;
        transition: all ease .5s;
        cursor: pointer!important;
    }*/

    .kullanici-bilgi {
        width: 500px;
        height: auto;
        display: table;
    }
    .kullanici-bilgi li {
        float: left;
    }

    .kullanici-bilgi li:nth-child(1n) {
        width: 150px;
        height: 40px;
        line-height: 40px;
       
    }
    .kullanici-bilgi li:nth-child(2n) {
        width: 350px;
        height: 40px;
        line-height: 40px;
      
    }

    .bayi-bilgi {
        width: 100%;
        height: auto;
        display: table;
    }
    .bayi-bilgi li {
        width: 100%;
        height: 40px;
        line-height: 40px;
        clear: both;
    }
    .bayi-bilgi li h2 {
        float: left;
        width: 200px;
        margin: 0;
        padding: 0;
        line-height: 40px;
    }
    .bayi-bilgi li span {
        float: left;
        width: auto;
    }
    .bayi-bilgi li address {
        float: left;
        width: auto;
    }
    .header-bg {
        background: #333333;
        width: 100%;
        height: 150px;
    }
    .dealer-area {
        width: 1280px;
        height: auto;
        margin: 50px auto;
    }
    .dealer-area>h1 {
        width: auto;
        font-size: 40px;
        color: #4c4c4c;
        font-weight: 700;
    }
    .dealer-area>ul {
        width: 1200px;
        height: auto;
        display: table;
        margin: 50px auto;
    }
    .dealer-area>ul>li {
        width: 350px;
        height: 200px;
        float: left;
        margin-right: 50px;
        margin-bottom: 50px;
        transition: all ease .5s;
        position: relative;
    }
    .dealer-area>ul>li>a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .dealer-area>ul>li:hover {
        transition: all ease .5s;
        cursor: pointer;
    }
    .dealer-area>ul>li:nth-child(3n) {
        margin-right: 0px;
    }
    .dealer-area>ul>li:nth-child(-n+3) {
        background: #c67d7f;
    }
    .dealer-area>ul>li:nth-child(-n+3):hover {
        background: #ffa3a6;
    }
    .dealer-area>ul>li:nth-child(n+4) {
        background: #7d90c6;
    }
    .dealer-area>ul>li:nth-child(n+4):hover {
        background: #94adf5;
    }
    .dealer-area>ul>li:nth-child(n+7) {
        background: #1ca800;
    }
    .dealer-area>ul>li:nth-child(n+7):hover {
        background: #28dd04;
    }
    .dealer-area>ul>li:nth-child(9) {
        background: #1ca800;
    }
    .dealer-area>ul>li:nth-child(9):hover {
        background: #28dd04;
    }
    .dealer-area>ul>li>figure {
        width: 80px;
        height: 80px;
        margin: auto;
        margin-top: 30px;
    }
    .dealer-area>ul>li>figure>img {
        width: 100%;
        height: 100%;
    }
    .dealer-area>ul>li>h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 300;
        color: #ffffff;
        margin-top: 20px;
    }
    .offer-area {
        width: 1280px;
        height: auto;
        display: table;
        margin: 50px auto;
        position: relative;
    }
    .offer-area>h1 {
        width: auto;
        font-size: 40px;
        color: #4c4c4c;
        font-weight: 700;
    }
    .offer-area>ul {
        width: 400px;
        height: auto;
        display: table;
        margin-top: 50px;
        float: left;
        padding-right: 0;
        border-right: 1px solid #ececec;
    }
    .offer-area>ul>li {
        height: 50px;
        float: left;
        padding: 10px;
    }
    .offer-area>ul>li>h1 {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        color: #4c4c4c;
    }
    .offer-area>ul>li:nth-child(1n) {
        width: 163px;
    }

    .offer-area>ul>li:nth-child(2n) {
        width: 235px;
    }
    
    .offer-area>ul>li>textarea {
        width: 100%;
        height: 100px;
    }
    .offer-area>ul>li:last-child {
        clear: both;
        width: 100%;
        height: 100px;
        float: none;
        text-align: center;
    }

    .offer-area>ul>li>h3 {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: #000000;
        margin-top: 20px;
    }
    .offer-area>ul>li>select {
        width: 100%;
        height: 50px;
        padding: 10px;
        font-size: 20px;
        font-weight: 300;
    }
    .offer-area>ul>li>input[type=text] {
        width: 100%;
        height: 50px;
        padding: 10px;
        font-size: 20px;
        font-weight: 300;
    }
    /*.offer-area>ul>li>button {
        margin: auto;
        width: 200px;
        height: 60px;
        background-color: #1ca800;
        background-image: url(/assets/images/add-wt.svg);
        background-repeat: no-repeat;
        background-position: 15px 13px;
        background-size: 15%;
        color: #ffffff;
        border: 1px solid #ffffff;
        transition: all ease .5s;
    }
    .offer-area>ul>li>button:hover {
        background-color: #ffffff;
        color: #1ca800;
        background-image: url(/assets/images/add-gr.svg);
        background-repeat: no-repeat;
        background-position: 15px 13px;
        background-size: 15%;
        border: 1px solid #1ca800; 
        transition: all ease .5s;
    }*/

    .offer-area>ul>li p-dropdown .p-dropdown-items-wrapper{background-color: #ffffff; border: 1px solid #aaaaaa;}
    .offer-area>ul>li p-dropdown .p-dropdown-item {padding: 5px; background-color: #ffffff; transition: all ease .5s;}
    .offer-area>ul>li p-dropdown .p-dropdown-item:hover {background-color: #cfcfcf; transition: all ease .5s;}
    .offer-area>ul>li p-dropdown .p-dropdown-label {
        position: relative;
        left: 0;
        width: 100%!important;
        top: 0;
    }

    .p-datatable .p-datatable-tbody > tr > td > img {
        height: 20px;
    }
    .offer-area>ul>li .p-hidden-accessible>input[type=text] {height: 40px;}
    .offer-area>ul>li p-inputnumber .p-inputnumber-input {
        width: 160px;
        height: 40px;
        padding: 10px;
    }

    .offer-area .buttons {
        width: 100%;
        height: 50px;
        margin-top: 50px;
    }

    .offer-area .buttons li:first-child {
        float: left;
        text-align: left;
    }

    .offer-area .buttons li:last-child {
        float: right;
        text-align: right;
    }
  
    .offers-detail {
        width: 800px;
        height: auto;
        float: left;
        padding-left: 25px;
        margin-top: 55px;
    }
    .offers-detail h2 {
        text-align: left;
        font-size: 24px!important;
        font-weight: 300!important;
        color: #000000;
        margin-top: 20px!important;
    }
    .offers-detail>p-table>.p-datatable>.p-datatable-wrapper table {
        width: 100%;
        height: auto;
        font-weight: 400;
        font-size: 14px;
    }
    .offers-detail>p-table>.p-datatable>.p-datatable-wrapper table thead tr th{
        padding: 10px;
        background-color: #e5e5e5;
    }
    .offers-detail>p-table>.p-datatable>.p-datatable-wrapper table tbody tr:nth-child(even){
        background-color: #ffffff;
    }
    .offers-detail>p-table>.p-datatable>.p-datatable-wrapper table tbody tr:nth-child(odd){
        background-color: #ececec;
    }
    .offers-detail>ul>li:first-child {
        font-size: 14px!important;
        font-weight: 500!important;
    }
    .offers-detail>ul>li:first-child>ul>li {
        border-bottom: 1px solid #000;
        border-right: 0px;
        border-left: 0px!important;
    }
    .offers-detail>ul>li>ul {
        display: flex;
    }
    .offers-detail>ul>li>ul>li:first-child {
        border-left: 1px dotted #000;
    }
    .offers-detail>ul>li>ul>li {
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        border-right: 1px dotted #000;
        border-bottom: 1px dotted #000;
    }
    .offers-detail>ul>li>ul>li:nth-child(6) {
        width: auto;
        float: left;
    }
    .offers-detail>ul>li>ul>li:nth-child(6)>input[type=number] {
        width: 50px;
        height: 30px;
        float: left;
        padding: 5px;
    }
    .offers-detail>ul>li>ul>li:nth-child(7)>select {
        width: 50px;
        float: left;
        height: 30px;
        padding: 5px;
    }
    .offers-detail>ul>li>ul>li:last-child {
        font-size: 12px!important;
        font-weight: 300!important;
    }
    .offers-detail h3 {
        display: inline-block;
        font-size: 16px;
    }
    .offers-detail time {
        display: inline-block;
        font-size: 16px;
        margin-left: 10px;
        color: rgb(146, 146, 146)
    }
    .offers-explain {
        margin-top: 50px;
    }
    .offers-detail>button {
        float: right;
        width: 200px;
        height: 60px;
        background-color: #1ca800;
        background-image: url(/assets/images/offer-wt.svg);
        background-repeat: no-repeat;
        background-position: 15px 13px;
        background-size: 15%;
        color: #ffffff;
        border: 1px solid #ffffff;
        transition: all ease .5s;
    }
    .offers-detail>button:hover {
        background-color: #ffffff;
        color: #1ca800;
        background-image: url(/assets/images/offer-gr.svg);
        background-repeat: no-repeat;
        background-position: 15px 13px;
        background-size: 15%;
        border: 1px solid #1ca800;
        transition: all ease .5s;
    }

    .teklif-not  {font-size: 10px; color:rgb(146, 146, 146); font-style: italic; margin-left: 10px;}
    .my-offers-area {
        width: 1280px;
        height: auto;
        margin: 50px auto;
    }
    .my-offers-area>h1 {
        width: auto;
        font-size: 40px;
        color: #4c4c4c;
        font-weight: 700;
    }
    .my-offers-area>.my-offers-list {
        width: 100%;
    }
    .my-offers-area>.my-offers-list>table {
        width: auto!important;
        width: max-content;
        border-top: 1px solid #cfcfcf;
        display: block;
        margin-bottom: 30px;
        overflow-x: scroll;
    }
    .my-offers-area>.my-offers-list>table tr td  {
        padding: 0!important;
    }
    .my-offers-area>.my-offers-list>table tr td .teklif-baslik {
        width: 100%!important;
        padding: 0!important;
        margin-bottom: 50px;
        border-bottom: 1px solid #cfcfcf;
    }

    .my-offers-area>.my-offers-list>table tr td .teklif-baslik tr th {
        padding: 0!important;
        vertical-align: middle;
        text-align: center;
    }
    .my-offers-area>.my-offers-list>table tr td .teklif-baslik tr th:first-child {
        width: 110px!important;
    }

    
    .my-offers-area>.my-offers-list>table tr td table .teklif-detay-header td {
        height: 44px;
        font-weight: 600;
        font-size: 16px!important;
        color: #8f4b4b!important;
        background-color: #fda6a6;
        border-right: 1px solid #ffffff;
        vertical-align: middle;
    }
    .my-offers-area>.my-offers-list>table .teklif-detay {
        background-color: #ffffff;
        border-bottom: 1px solid #adadad;
        border-left: 1px solid #adadad;
        border-right: 1px solid #adadad;
    }
    .my-offers-area>.my-offers-list>table>tr>th {
        font-size: 18px;
        color: #ffffff;
        padding: 15px 25px;
        background-color: #0065b2;
        vertical-align: middle;
    }
    .my-offers-area>.my-offers-list>table>tr>th:nth-child(8) {
        width: 150px;
        text-align: center;
    }
    .my-offers-area>.my-offers-list>table>tr>td {
        font-size: 14px;
        vertical-align: top;
    }

    .my-offers-area>.my-offers-list>table>tr>td>img {
        width: 20px;
        height: 20px;
    }
    .my-offers-area>.my-offers-list>table>tr>td>table {
        width: 100%;
        min-width: 840px;
    }
    .my-offers-area>.my-offers-list>table>tr>td>table>tr>th {
        padding: 5px;
        font-size: 14px;
        height: 44px;
        color: #000000;
        padding: 5px 10px;
        background-color: #aaaaaa;
    }
    .my-offers-area>.my-offers-list>table>tr>td>table>tr>th h3 {
        padding: 0;
        margin: 0;
    }
    .my-offers-area>.my-offers-list>table>tr>td>table>tr>th:last-child {
        text-align: center;
        
    }
    .my-offers-area>.my-offers-list>table>tr>td>table>tr>td {
        font-size: 12px;
        color: #000000;
        vertical-align: middle;
        text-align: center;
        border-right: 1px solid #cfcfcf;
        height: 30px;
    }
    .my-offers-area>.my-offers-list>table>tr>td>table>tr>td:first-child {
        border-left: 2px solid #cfcfcf;
    }
    .my-offers-area>.my-offers-list>table>tr>td>table>tr>td p{
        margin: 0;
        padding: 0;
    }

    .my-offers-area>.my-offers-list>table>tr>td>table>tr>td:last-child {
        text-align: center;
        width: 150px;
    }

    .teklif-info {margin-left: 10px;}
    .teklif-info i {vertical-align: middle;}

    .my-orders-area {
        width: 1280px;
        height: auto;
        margin: 50px auto;
    }
    .my-orders-area>h1 {
        width: auto;
        font-size: 40px;
        color: #4c4c4c;
        font-weight: 700;
    }
    .my-orders-area>.my-orders-list {
        width: 100%;
        overflow-x: scroll;
    }
    .my-orders-area>.my-orders-list>table {
        min-width: 100%;
        width: max-content;
        border-top: 1px solid #cfcfcf;
        display: block;
    }
    .my-orders-area>.my-orders-list>table>tr:nth-child(odd) {
        background-color: #e9e9e9;
    }
    .my-orders-area>.my-orders-list>table>tr:nth-child(even) {
        background-color: #ffffff;
    }
    .my-orders-area>.my-orders-list>table>tr>th {
        font-size: 18px;
        color: #000000;
        padding: 15px 25px;
        background-color: #aaaaaa;
    }
    .my-orders-area>.my-orders-list>table>tr>th:first-child {
        width: 30px!important;
        text-align: center;
    }
    .my-orders-area>.my-orders-list>table>tr>th:nth-child(9) {
        width: 150px;
        text-align: center;
    }
    .my-orders-area>.my-orders-list>table>tr>td {
        font-size: 14px;
        color: #000000;
        padding: 15px 25px;
        border-right: 1px dotted #cfcfcf;
        vertical-align: middle;
    }
    .my-orders-area>.my-orders-list>table>tr>td:last-child {
        width: 50px!important;
        text-align: center;
    }
    .my-orders-area>.my-orders-list>table>tr>td>img {
        width: 20px!important;
        height: auto!important;
    }
    .my-orders-area>.my-orders-list>table>tr>td:first-child>img {
        width: 30px!important;
        height: 30px!important;
    }
    .my-orders-area>.my-orders-list>table>tr>td>table {
        width: 100%;
        min-width: 840px;
    }
    .my-orders-area>.my-orders-list>table>tr>td>table>tr>th {
        padding: 5px;
        font-size: 14px;
        color: #000000;
        padding: 5px 10px;
        background-color: #aaaaaa;
    }
    .my-orders-area>.my-orders-list>table>tr>td>table>tr>th:last-child {
        text-align: center;
        width: 150px;
    }
    .my-orders-area>.my-orders-list>table>tr>td>table>tr>td {
        padding: 5px;
        font-size: 12px;
        color: #000000;
        padding: 5px 10px;
    }
    .my-orders-area>.my-orders-list>table>tr>td>table>tr>td:last-child {
        text-align: center;
        width: 150px;
    }

    .bekliyor {display: block!important; width: 100%!important; text-align: center; padding: 5px 10px; font-weight: 600; background-color: #ff8c00; color: #663a03; border-radius: 3px;}
    .tamamlandi {display: block!important; width: 100%!important; text-align: center; padding: 5px 10px; font-weight: 600; background-color: #c9ffc4; color: #016106; border-radius: 3px;}
    .iptal-edildi {display: block!important; width: 100%!important; text-align: center; padding: 5px 10px; font-weight: 600; background-color: #fc6767; color: #5a0202; border-radius: 3px;}

    .my-orders-list p-table {width: auto!important;}
    .my-orders-list p-table > div > div > table {table-layout: auto!important;}
    .my-orders-list p-table tr td {overflow-x: scroll!important; white-space: nowrap!important;}
    .siparis-detay-header {background-color: #333333; color:rgba(255, 255, 255, 0.8);}
    .siparis-detay {width: 100%!important; table-layout: auto!important;}
    .siparis-detay tr td {padding: 5px!important; white-space: nowrap!important;}

    .user-properties-area {
        width: 1280px;
        margin: 50px auto;
    }
    .user-properties-area h2 {
        text-align: left;
        font-size: 24px!important;
        font-weight: 300!important;
        color: #000000;
        margin-top: 20px!important;
    }
    .user-properties-area h3 {
        text-align: left;
        font-size: 20px!important;
        font-weight: 300!important;
        color: #000000;
        margin-top: 20px!important;
    }
    .user-properties {
        width: 100%;
        height: auto;
    }
    .user-properties li h2 {
        text-align: left;
        font-size: 18px!important;
        font-weight: 300!important;
        color: #000000;
        margin-top: 20px!important;
    }
    .user-properties li h2 span {
        color: #ff0000;
    }
    .user-properties li input {
        width: 400px;
        height: auto;
        padding: 10px 15px;
    }
    .user-properties li button {
        margin: 30px auto;
        width: 200px;
        height: 60px;
        background-color: #1ca800;
        color: #ffffff;
        border: 1px solid #ffffff;
        transition: all ease .5s;
    }
    .user-properties li button:hover {
        background-color: #ffffff;
        color: #1ca800;
        border: 1px solid #1ca800;
        transition: all ease .5s;
    }
    .b2b-account-area {
        width: 1280px;
        margin: 50px auto;
    }
    .b2b-account-area h2 {
        text-align: left;
        font-size: 24px!important;
        font-weight: 300!important;
        color: #000000;
        margin-top: 20px!important;
    }
    .b2b-account-area h3 {
        text-align: left;
        font-size: 20px!important;
        font-weight: 300!important;
        color: #000000;
        margin-top: 20px!important;
    }
    .b2b-account {
        width: 100%;
        height: auto;
    }
    .b2b-account>ul {
        width: 100%;
        height: auto;
        display: table;
        background-color: #e5e5e5;
    }
    .b2b-account>ul>li {
        float: left;
        width: auto;
        border-right: 1px solid #ffffff;
        height: auto;
        padding: 15px;
    }
    .b2b-account>ul>li:last-child {
        border-right: 0px;
    }
    .b2b-account>ul>li:first-child>ul {
        width: 400px;
        height: 100%;
    }
    .b2b-account>ul>li:first-child>ul>li {
        float: left;
        height: 50px;
        padding: 15px;
    }
    .b2b-account>ul>li:first-child>ul>li:nth-child(1n) {
        font-weight: 600;
        width: 125px;
    }
    .b2b-account>ul>li:first-child>ul>li:nth-child(2n) {
        font-weight: 300;
        width: 250px;
    }
    .b2b-account>ul>li:nth-child(2)>ul {
        width: 400px;
        height: 100%;
    }
    .b2b-account>ul>li:nth-child(2)>ul>li {
        float: left;
        height: 50px;
        padding: 15px;
    }
    .b2b-account>ul>li:nth-child(2)>ul>li:nth-child(1n) {
        font-weight: 600;
        width: 125px;
    }
    .b2b-account>ul>li:nth-child(2)>ul>li:nth-child(2n) {
        font-weight: 300;
        width: 250px;
    }
    .b2b-account>ul>li:nth-child(3)>ul {
        width: 385px;
        height: 100%;
    }
    .b2b-account>ul>li:nth-child(3)>ul>li {
        float: left;
        height: 50px;
        padding: 15px;
    }
    .b2b-account>ul>li:nth-child(3)>ul>li:nth-child(1n) {
        font-weight: 600;
        width: 150px;
    }
    .b2b-account>ul>li:nth-child(3)>ul>li:nth-child(2n) {
        font-weight: 300;
        width: 235px;
    }
    .account-summary {
        width: 1000px;
        height: auto;
        margin: 50px auto;
    }
    .account-summary>ul:nth-child(1) {
        width: 1000px;
        height: auto;
        display: table;
        margin-bottom: 30px;
        background-color: #ececec;
    }
    .account-summary>ul:nth-child(1)>li {
        float: left;
        width: 200px;
        padding: 10px 15px;
        text-align: center;
    }
    .account-summary>ul:nth-child(1)>li:nth-child(-n+5) {
        font-weight: 600;
        border-bottom: 1px solid #757f94;
    }
    .account-summary>ul:nth-child(2) {
        width: 600px;
        height: auto;
        display: table;
        margin-bottom: 30px;
        margin: auto;
        background-color: #ececec;
    }
    .account-summary>ul:nth-child(2)>li {
        float: left;
        width: 200px;
        padding: 10px 15px;
        text-align: center;
    }
    .account-summary>ul:nth-child(2)>li:nth-child(-n+3) {
        font-weight: 600;
        border-bottom: 1px solid #757f94;
    }
    .account-detail {
        width: 1280px;
        border-top: 1px solid #cfcfcf;
        display: block;
        overflow-x: scroll;
    }
    .account-detail>table {
        width: 100%;
        border-top: 1px solid #cfcfcf;
        display: block;
    }
    .account-detail>table>tr {
        width: 100%;
    }
    .account-detail>table>tr:nth-child(odd) {
        background-color: #e9e9e9;
    }
    .account-detail>table>tr:nth-child(even) {
        background-color: #ffffff;
    }
    .account-detail>table>tr>th {
        font-weight: 600;
        padding: 10px 15px;
    }
    .account-detail>table>tr>td {
        font-weight: 600;
        padding: 10px 15px;
    }
    .contact {
        width: 1280px;
        height: auto;
        margin: 50px auto;
    }
    .contact>ul {
        width: 100%;
        height: auto;
        display: table;
    }
    .contact>ul>li {
        float: left;
        width: 640px;
        padding: 20px;
    }
    .contact>ul>li i {
        color: #c06c31;
        margin-right: 7px;
        vertical-align: middle;
    }
    .contact>ul>li>h1 {
        font-size: 22px;
        color: #2e2e2e;
        line-height: 36px;
    }
    .contact>ul>li>h2 {
        font-size: 18px;
        color: #2e2e2e;
        line-height: 36px;
        margin-top: 30px;
    }
    .contact>ul>li>address {
        line-height: 36px;
    }
    .contact>ul>li>address>a {
        text-decoration: none;
        color: #2e2e2e;
        transition: all ease .5s;
    }
    .contact>ul>li>address>a:hover {
        color: #cfcfcf;
        transition: all ease .5s;
    }
    .contact>ul>li>.mail {
        line-height: 36px;
    }
    .contact>ul>li>.mail>a {
        text-decoration: none;
        color: #2e2e2e;
        transition: all ease .5s;
    }
    .contact>ul>li>.mail>a:hover {
        color: #cfcfcf;
        transition: all ease .5s;
    }
    .contact>ul>li>.phone {
        line-height: 36px;
    }
    .contact>ul>li>.phone>a {
        text-decoration: none;
        color: #2e2e2e;
        transition: all ease .5s;
    }
    .contact>ul>li>.phone>a:hover {
        color: #cfcfcf;
        transition: all ease .5s;
    }
    .contact>ul>li>form>div>span {
        display: block;
        font-size: 14px;
        color: #2e2e2e;
        margin: 10px 0;
    }
    .contact>ul>li>form>div>input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #cfcfcf;
        padding: 10px;
        font-size: 12px;
        color: #2e2e2e;
        background: #ffffff;
    }
    .contact>ul>li>form>div>button {
        width: 150px;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #cfcfcf;
        padding: 10px;
        font-size: 12px;
        color: #2e2e2e;
        background: #ffffff;
        margin-top: 30px;
        transition: all ease .5s;
        cursor: pointer;
    }
    .contact>ul>li>form>div>button:hover {
        background: #cfcfcf;
        transition: all ease .5s;
    }
    .contact>ul>li>form>div>textarea {
        width: 100%;
        height: 100px;
        border-radius: 10px;
        border: 1px solid #cfcfcf;
        padding: 10px;
        font-size: 12px;
        color: #2e2e2e;
    }
}